import React, { useState, useEffect } from 'react';

import { Container, Row, Col, Input, InputGroup } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import axios from 'src/utils/axios';

import OutsideClickHandler from 'react-outside-click-handler';
import FileTree from './components/FileTree';
import FileModal from './components/FileModal';
import EditorSwitch from './Editors/EditorSwitch';

const Editor = () => {
	const { id } = useParams();

	const [activity, setActivity] = useState(null);
	const [images, setImages] = useState(null);
	const [boards, setBoards] = useState(null);

	const [searchFilter, setSearchFilter] = useState('');
	const [file, setFile] = useState(null);
	const [editor, setEditor] = useState(null);

	const [selectedFile, setSelectedFile] = useState('');

	const [showSidebar, setShowSidebar] = useState(true);
	const [fileTreeActive, setFileTreeActive] = useState(false);

	const [fileModal, setFileModal] = useState(false);

	useEffect(() => {
		let getDetails = async () => {
			try {
				const response = await axios.get(`/activity/${id}`);
				const { activity, images, boards } = response.data;
				setActivity(activity);

				const newImages = images.map((image, index) => {
					return {
						...image,

						title: image.title,
						description: image.description,
						type: 'png',
						data: {
							url: image.url
						}
					};
				});
				setImages(newImages);

				const newBoards = boards.map((board, index) => {
					return {
						title: board.board_name,
						type: 'board',
						data: {
							backgroundImage: board.location
						}
					};
				});
				setBoards(newBoards);
			} catch (error) {
				console.error(error);
			}
		};
		getDetails();
	}, [id]);

	// let selectedEditor = <NoEditor file={fileEdit} />
	// if (fileEdit?.type === "png") selectedEditor = <ImageViewer file={fileEdit} />

	const files = [
		{
			title: 'Images',
			editable: false,
			icon: '',
			actions: {
				onClick: (file) => {
					setFile(file);
					setEditor('ImageViewer');
				}
			},
			children: images
		},
		{
			title: 'Videos',
			editable: false,
			icon: '',
			actions: {
				onClick: (file) => {
					setFile(file);
					setEditor('VideoViewer');
				}
			},
			children: [
				{
					title: 'intro.mp4',
					type: 'file',
					data: {
						url: 'https://www.youtube.com/watch?v=ysz5S6PUM-U'
					}
				},
				{
					title: 'outro.mp4',
					type: 'file',
					data: {
						url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'
					}
				}
			]
		},
		{
			title: 'Sounds',
			editable: false,
			icon: '',
			actions: {
				onClick: (file) => {
					setFile(file);
					setEditor('');
				}
			},
			children: [
				{
					title: 'mockup.mp3',
					type: 'file'
				},
				{
					title: 'woof.mp3',
					type: 'file'
				}
			]
		},
		{
			title: 'Fonts',
			editable: false,
			icon: '',
			actions: {
				onClick: (file) => {
					setFile(file);
					setEditor('');
				}
			},
			children: [
				{
					title: 'Things',
					icon: '',
					children: [
						{
							title: 'Scoring',
							icon: '',
							children: [
								{
									title: 'February.pdf',
									type: 'file'
								},
								{
									title: 'March.pdf',
									type: 'file'
								},
								{
									title: 'April.pdf',
									type: 'file'
								}
							]
						},

						{
							title: 'February.pdf',
							type: 'file'
						},
						{
							title: 'March.pdf',
							type: 'file'
						},
						{
							title: 'April.pdf',
							type: 'file'
						}
					]
				},

				{
					title: 'February.pdf',
					type: 'file'
				},
				{
					title: 'March.pdf',
					type: 'file'
				},
				{
					title: 'April.pdf',
					type: 'file'
				}
			]
		},
		{
			title: 'Boards',
			editable: false,
			actions: {
				onClick: (file) => {
					setFile(file);
				},
				addFile: () => {}
			},
			icon: '',
			children: [{
				title: 'The Prison.board',
				type: 'board',
				data: {
					name: "The Prison",
					backgroundImage: "https://myactivityhub-data.s3.eu-west-2.amazonaws.com/15b201a0-afc7-4c2a-8965-f7abaa09fd6e"
				}
			}]
		},
		{
			title: 'Views',
			editable: false,
			icon: '',
			actions: {
				onClick: (file) => {
					setFile(file);
					setEditor('');
				}
			},
			children: [
				{
					title: 'View 1',
					type: 'file'
				},
				{
					title: 'View 2',
					type: 'file'
				},
				{
					title: 'View 3',
					type: 'file'
				}
			]
		}
	];

	useEffect(() => {
		console.log(selectedFile)
		if (selectedFile.type == "board")
			setEditor('BoardEditor');
	}, [selectedFile])

	// if (!activity)return <LoadingSpinner fullscreen message={"Loading data"}/>



	return (
		<Container fluid style={{ height: '100vh', display: 'flex', flexFlow: 'column' }}>
			<Row
				style={{
					paddingLeft: '1rem',
					borderBottom: '1px solid grey',
					height: 40
				}}
			>
				{!showSidebar && (
					<i
						className="fa fa-arrow-right"
						onClick={() => {
							setShowSidebar(true);
						}}
					/>
				)}
				<h4>Activity Editor</h4>
				<h4 className="mx-auto">{activity ? activity.activity_name : <i className="mx-auto fa fa-cog fa-spin ml-2" />}</h4>
				<Link to="/" className="btn btn-outline-orange float-right">
					<i className="fa fa-arrow-left"></i> Back to home
				</Link>
			</Row>
			<Row
				style={{
					flexGrow: 1
				}}
			>
				<Col md={3} className="pl-0 pr-0" style={{ display: showSidebar ? 'block' : 'none' }}>
					<small className="ml-1 mr-1">
						Explorer
						<i
							className="fas fa-file-upload fa-2x float-right"
							onClick={() => {
								setFileModal(true);
							}}
						/>
						{showSidebar && (
							<i
								className="fa fa-arrow-left float-right"
								onClick={() => {
									setShowSidebar(false);
								}}
							/>
						)}
					</small>

					<InputGroup>
						<Input
							style={{
								height: 'calc(1.5em + 2px)',
								backgroundColor: 'white',
								color: 'Black',
								borderRadius: 0
							}}
							value={searchFilter}
							onChange={(e) => {
								setSearchFilter(e.target.value);
							}}
							placeholder="Search..."
						></Input>
					</InputGroup>

					<OutsideClickHandler
						display={'block'}
						onOutsideClick={() => {
							setFileTreeActive(false);
						}}
					>
						<FileTree
							data={files}
							initialOpen={false}
							style={{
								borderTop: '1px solid grey',
								border: fileTreeActive ? 'yellow 1px solid' : ''
							}}
							onClick={() => {
								setFileTreeActive(true);
							}}
							setSelectedFile={(f) => {
								setSelectedFile(f);
								console.log(f)
							}}
							// setSelectedFile={(file) => { if (fileTreeActive) setSelectedFile(file.uuid) }}
							actions={{
								onClick: (e) => {
									setFile(e);
								}
							}}
							filter={searchFilter}
						/>
					</OutsideClickHandler>
				</Col>
				<Col md={9} className="pr-0 pl-0" style={{ borderLeft: '1px solid grey', backgroundColor: "white" }}>
					<EditorSwitch type={editor} file={selectedFile} />
				</Col>
			</Row>
			<FileModal
				isOpen={fileModal}
				toggle={() => {
					setFileModal(!fileModal);
				}}
				onUpload={async (data) => {
					try {
						console.log(data);
						// if (data.type === "image/png")
						await axios.post(`/activity/${id}/uploadImage`, data);
					} catch (error) {
						console.log(error);
					}
				}}
			/>
		</Container>
	);
};

export default Editor;
