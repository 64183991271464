import { React, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Card, CardTitle, CardBody, FormGroup, Input, Button, Alert, Label, Row, Col, Spinner } from 'reactstrap';

import axios from 'src/utils/axios';

import { useApp } from 'src/appContext';
import { Auth } from 'aws-amplify';

const Login = () => {
	const { updateCurrentUser, setUser } = useApp();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [status, setStatus] = useState('');

	const history = useHistory();
	const handleSubmit = async (e) => {
		try {
			e.preventDefault();
			setStatus('signing');
			if (!email || !password) return setStatus('blank');
			// const token = "abc";

			const user = await Auth.signIn(email, password);

			// var res = await axios.post(`/auth/login`, {
			// 	email: email,
			// 	password: password
			// });
			// console.log(res);
			// const { user, token } = res.data;

			console.log(user)
			updateCurrentUser();
			history.push(`/`);
			// if (!token) return setStatus('wrong');

			setStatus('Logged in');
			// setToken(token);
			// setUser(user);
		} catch (error) {
			setStatus('error');
			console.error(error);
		}
	};


	return (
		<Container>
			{/* <Form className="login-form" onSubmit={handleSubmit}> */}
			<Row>
				<Col sm={{ offset: 3, size: 6 }}>
					<Card>
						<CardTitle style={{ textAlign: 'center' }}>
							<h2> My Activity Hub </h2>
							<hr />
						</CardTitle>
						<CardBody>
							<CardTitle tag="h5">Please sign in</CardTitle>
							<FormGroup>
								<Label for="email"> Email Address</Label>
								<Input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
							</FormGroup>
							<FormGroup>
								<Label for="password"> Password </Label>
								<Input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
							</FormGroup>
							<Button onClick={handleSubmit} color="info" block={true}>
								{status === 'signing' ? <Spinner /> : 'Sign In'}
							</Button>
							<br />
							{status === 'wrong' && <Alert color="danger">Email or password incorrect</Alert>}
							{status === 'blank' && <Alert color="danger">Email or password is blank</Alert>}
						</CardBody>
					</Card>
				</Col>
			</Row>
			{/* </Form> */}
		</Container>
	);
};

export default Login;
