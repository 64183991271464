import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import socket from 'src/utils/socket';
import PhoneView from './PhoneView';

export default function Question({ state }) {
	const [answer, setAnswer] = useState('');
	const [answerVersion, setAnswerVersion] = useState(0);

	const answerHandler = useCallback(
		(p) => {
			if (p.answer_text_version <= answerVersion) return;
			setAnswerVersion(p.answer_text_version);
			setAnswer(p.answer_text);
		},
		[answerVersion]
	);
	useEffect(() => {
		socket.on('answer_text', answerHandler);
		return () => socket.off(answerHandler);
	}, [answerHandler]);

	return (
		<>
			{state.photo_based ? (
				<>
					<PhoneView background={state.roomBackground}>
						<div style={{ textAlign: 'center', background: 'rgba(255,255,255,0.9)', borderRadius: '25px', padding: '10px', marginBottom: '20px' }}>
							<p style={{ whiteSpace: 'pre-line' }}>{state?.taskDescription}</p>
							<div style={{ border: '1px solid black', padding: '4dpx' }}>{answer}&nbsp;</div>
							<br />
							<Button disabled>Submit Answer</Button>
						</div>
					</PhoneView>
					{/* <Col md={1}>&nbsp;</Col> */}
					<PhoneView background={state.roomBackground}>
						{state.taskImage && <img src={state.taskImage} alt="Task" style={{ maxWidth: '100%', maxHeight: '60vh' }} />}
					</PhoneView>
				</>
			) : (
				<PhoneView background={state.roomBackground}>
					{state.taskImage && <img src={state.taskImage} alt="Task" style={{ maxWidth: '100%', maxHeight: '30vh' }} />}
					<h5 style={{ background: 'rgba(255,255,255,0.9)', borderRadius: '25px', padding: '10px', marginTop: '5px' }}>{state?.taskTitle}</h5>
					<hr />
					<div style={{ textAlign: 'center', background: 'rgba(255,255,255,0.9)', borderRadius: '25px', padding: '10px', marginBottom: '20px' }}>
						<p style={{ whiteSpace: 'pre-line' }}>{state?.taskDescription}</p>
						<div style={{ border: '1px solid black', padding: '4dpx' }}>{answer}&nbsp;</div>
					</div>
					<br />
					<Button disabled>Submit Answer</Button>
				</PhoneView>
			)}
		</>
	);
}
