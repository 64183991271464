import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import OutsideClickHandler from 'react-outside-click-handler';
// import ContextMenu from 'tui-context-menu'; /* ES6 */

import FileContext from './FileContext';

const Folder = (props) => {
	const [title, setTitle] = useState(props.title);
	const [edit, setEdit] = useState(false);

	const { uuid, depth } = props;
	// const { onClick, setSelectedFile } = props.actions;
	const { selectedFile, setSelectedFile } = useContext(FileContext);

	const thisUuid = `${uuid}/${title}`;

	let currentDepth = depth ?? 0;

	const thisFile = {
		...props,
		uuid: thisUuid,
		rename: () => {
			setEdit(true);
		}
	};
	return (
		<div
			className={`file selectable ${thisUuid === selectedFile?.uuid ? 'selected' : ''}`}
			onMouseDown={() => {
				// if ((thisUuid == selectedFile.uuid))
				// onClick(thisFile);
				// else selectFile(thisUuid)
			}}
			onMouseUp={() => {
				if (setSelectedFile) setSelectedFile(thisFile);
			}}
			style={{ paddingLeft: currentDepth * 12 + 12 }}
		>
			<i className="fas fa-file fa-xs pr-2" />
			{edit ? (
				<OutsideClickHandler
					display={'contents'}
					onOutsideClick={() => {
						setEdit(false);
					}}
				>
					<input
						value={title}
						onChange={(e) => {
							setTitle(e.target.value);
						}}
					/>
				</OutsideClickHandler>
			) : (
				<small>{title}</small>
			)}
		</div>
	);
};

File.propTypes = {
	title: PropTypes.string.isRequired
};

export default Folder;
