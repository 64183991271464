import { useState, useCallback, useEffect } from 'react';
import {
	Container,
	Row,
	Col,
	Button,
	ListGroup,
	ListGroupItem,
	Spinner,
	UncontrolledTooltip,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap';
import graphql from 'src/utils/graphql';
import { Link } from 'react-router-dom';
import { AskText } from 'src/components/questions';

export default function TradingWarsEditor() {
	const [moments, setMoments] = useState([]);
	const [products, setProducts] = useState([]);

	const [loading, setLoading] = useState(true);

	const fetchData = useCallback(async () => {
		try {
			var { products, moments } = await graphql({
				query: `
				{
					products {
						id
						name
						color
						background
					} 
					moments {
						id
						title
						cost
						line1
						line2
						line3
						photo
					}
				}`
			});
			console.log(products, moments);
			setProducts(products);
			setMoments(moments);
			setLoading(false);
		} catch (error) {
			alert(error.message);
		}
	}, []);

	const updateProduct = async (input) => {
		try {
			await graphql({
				query: `
				mutation($input: ProductInput!) {
					saveProduct(input: $input)
				}`,
				variables: {
					input
				}
			});
			await fetchData();
		} catch (error) {
			alert(error.message);
		}
	};

	const resetMoment = async () => {
		try {
			await graphql({
				query: `mutation{
					resetMoment
				}`
			});
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<Container>
			<Button className="float-right mt-1" onClick={resetMoment}>
				Reset Game
			</Button>
			<h3>Trading Wars Editor</h3>
			<hr />
			<Row>
				<Col md={6}>
					<h3>Moments</h3>
					{loading && <Spinner />}
					<ListGroup>
						{moments.map((moment) => (
							<ListGroupItem key={moment.id}>
								(Slide {moment.id}) {moment.title} {moment.photo && <b>[IMAGE]</b>}
								<EditBTN to={`/trading-wars/editor/moment/${moment.id}`} />
							</ListGroupItem>
						))}
					</ListGroup>
				</Col>
				<Col md={6}>
					<h3>Products</h3>
					{loading && <Spinner />}
					<ListGroup>
						{products.map((product) => (
							<ListGroupItem key={product.id}>
								{product.name}
								<AskText value={product.name} onSubmit={(value) => updateProduct({ id: product.id, name: value })} />
								{/* circle color div */}
								<AskText
									title="Edit Background"
									value={product.background}
									onSubmit={(value) => updateProduct({ id: product.id, background: value })}
								>
									<div
										style={{
											backgroundColor: product.background,
											borderRadius: '25%',
											display: 'inline-block',
											marginLeft: '1rem',
											border: '1px solid black',
											width: '50px',
											textAlign: 'center'
										}}
									>
										<span style={{ color: product.color, fontWeight: 'bold' }}>123</span>
									</div>
								</AskText>
								&nbsp;
								<AskText title="Edit Text" value={product.color} onClick={(value) => updateProduct({ id: product.id, color: value })}>
									Change Text Color
								</AskText>
							</ListGroupItem>
						))}
					</ListGroup>
				</Col>
			</Row>
		</Container>
	);
}

function EditBTN(props) {
	const { to } = props;
	return (
		<Link to={to}>
			<Button size="sm" className="float-right">
				<i className="fa fa-pen" />
			</Button>
		</Link>
	);
}
