import { useEffect, useState, useCallback } from 'react';
import axios from 'src/utils/axios';
import Spinner from 'src/components/spinner';

export default function ImageViewer() {
	const [images, setImages] = useState(null);

	const loadImages = useCallback(async () => {
		var res = await axios.get('/images');
		setImages(res.data);
	}, [setImages]);

	useEffect(() => {
		loadImages();
		var intervalRef = setInterval(loadImages, 5000);

		return () => {
			clearInterval(intervalRef);
		};
	}, [loadImages]);

	if (!images) return <Spinner />;
	return (
		<div>
			{images.map((img) => {
				return (
					<div>
						<img style={{ maxWidth: '300px' }} src={img.image_url} alt=""></img>
					</div>
				);
			})}
		</div>
	);
}
