import Home from './pages/Home';
import Login from './pages/Login';
import Editor from './pages/Editor';
import Event from './pages/Event';
import Activity from './pages/Activity';
import Present from './pages/Present';
import Slideshow from './pages/Slideshow';
import Room from 'src/pages/Room/Room';
import RoomConfigure from 'src/pages/RoomConfigure';
import Task from 'src/pages/Task';
import ImageViewer from 'src/pages/ImageViewer';
import EventProgress from './pages/Event/EventProgress.js';
import EventScoring from 'src/pages/Event/EventScoring';
import Settings from './pages/Settings';
import axios from 'axios';

import TradingWars from './pages/TradingWars';
import TradingWarsEditor from './pages/TradingWarsEditor';
import TradingWarsMomentEditor from './pages/TradingWarsEditor/moment';

import Billing from './pages/Billing';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AppProvider, useApp } from './appContext';

import config from './config';
import Amplify, { Auth } from 'aws-amplify';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'; // default theme

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		// identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID
	},
	API: {
		endpoints: [
			{
				name: 'api',
				endpoint: 'https://api.myactivityhub.net',
				custom_header: async () => {
					// return { Authorization: 'token' };
					// Alternatively, with Cognito User Pools use this:
					return { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` };
					// return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
				}
			}
		]
	}
}); //Check this: https://serverless-stack.com/chapters/configure-aws-amplify.html

function App() {
	const { isAuthenticated } = useApp();
	// if (user?.signInUserSession?.accessToken) axios.defaults.headers.common.Authorization = user.signInUserSession.accessToken.jwtToken;

	return (
		<div className="App">
			<Router>
				<Switch>
					<Route path="/present/:id" exact component={Present} />
					<Route path="/images" exact component={ImageViewer} />
					<Route path="/event/:id" exact children={<Event />} />
					<Route path="/event/:id/scoring" exact children={<EventScoring />} />
					<Route path="/game/:id/progress" exact children={<EventProgress />} />
					<Route path="/slideshow/:id" exact component={Slideshow} />
					<Route path="/billing" exact children={<Billing />} />
					<Route path="/trading-wars" exact children={<TradingWars />} />

					{
						//If there is no token, you should no be able to access anything except for login
						isAuthenticated ? (
							<>
								<Route exact path="/" component={Home} />
								<Route path="/settings" exact component={Settings} />
								<Route path="/activity/editor/:id" exact component={Editor} />
								<Route path="/activity/:id" exact component={Activity} />
								<Route path="/room/:id" exact component={Room} />
								<Route path="/room/:id/configure" exact component={RoomConfigure} />
								<Route path="/task/:id" exact component={Task} />
								<Route path="/trading-wars/editor" exact children={<TradingWarsEditor />} />
								<Route path="/trading-wars/editor/moment/:id" exact children={<TradingWarsMomentEditor />} />
							</>
						) : (
							<Route path="/" component={Login} />
						)
					}
				</Switch>
			</Router>
		</div>
	);
}

export default () => (
	<AmplifyProvider>
		<AppProvider>
			<App />
		</AppProvider>
	</AmplifyProvider>
);
