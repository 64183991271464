import { useState, useCallback, useEffect, useRef } from 'react';
import { Container, Badge, Row, Col } from 'reactstrap';
import Table from './components/table';
import graphql from 'src/utils/graphql';
import './style.css';
export default function TradingWars() {
	const [changeMomentPermission, setChangeMomentPermission] = useState(false);
	const [moment, setMoment] = useState(null);
	const [error, setError] = useState(null);

	const fetchMoment = useCallback(async () => {
		try {
			const { moment } = await graphql({
				query: `
			{
				moment {
					id
					title
					cost
					line1
					line2
					line3
					productPrices {
						id
						product {
							id
							name
							color
							background
						}
						advert
						logistics
						productCost
						payout
					}
					photo
				} 
			}
			`
			});
			setMoment(moment);
			setError(null);
		} catch (error) {
			console.log('Error...');
			console.log(error);
			setError(error);
		}
	}, []);

	// Create a ref to store the timeout ID
	const timeoutRef = useRef(null);

	useEffect(() => {
		// Define scheduleFetch inside the useEffect
		const scheduleFetch = () => {
			const offset = Math.floor(Math.random() * 2000) - 1000; // Random offset between -1000 ms and +1000 ms
			const delay = 3500 + offset; // 4 seconds +/- 1 second

			timeoutRef.current = setTimeout(() => {
				fetchMoment();
				scheduleFetch(); // Schedule next fetch
			}, delay);
		};

		// Initial fetch
		fetchMoment();

		// Start the scheduled fetch
		scheduleFetch();

		// Cleanup function to clear the timeout
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [fetchMoment]); // fetchMoment is a dependency

	// set background on page load
	useEffect(() => {
		document.body.style.backgroundImage = `url(https://cdn.discordapp.com/attachments/557891960334057474/1025429286628368435/1.png)`;
		document.body.style.backgroundSize = 'cover';
		document.body.style.backgroundRepeat = 'no-repeat';
		document.body.style.backgroundPosition = 'center';
		document.body.style.overflow = 'hidden';
		document.body.style.minHeight = '100vh';

		return () => {
			document.body.style.backgroundImage = '';
			document.body.style.backgroundSize = '';
			document.body.style.backgroundRepeat = '';
			document.body.style.backgroundPosition = '';
			document.body.style.overflow = '';
			document.body.style.minHeight = '';
		};
	}, []);

	const switchMoment = async (action) => {
		try {
			await graphql({
				query: `
			mutation {
				${action}Moment
			}`
			});
			setError(null);
		} catch (error) {
			console.log('Error...');
			console.log(error);
			setError(error);
		}
	};

	const handleClick = useCallback(
		async (event) => {
			if (!changeMomentPermission) return;
			await switchMoment('next');
			await fetchMoment();
		},
		[changeMomentPermission, fetchMoment]
	);

	const handleKeyPress = useCallback(
		async (event) => {
			let k = event.key.toLowerCase();
			// Swap page up and page down for arrow keys
			console.log(k);
			if (k === 'pageup') k = 'arrowleft';
			if (k === 'pagedown') k = 'arrowright';
			switch (k) {
				case 'arrowright':
					if (!changeMomentPermission) return;
					await switchMoment('next');
					await fetchMoment();
					break;

				case 'arrowleft':
					if (!changeMomentPermission) return;
					await switchMoment('prev');
					await fetchMoment();
					break;

				case 'a':
					if (event.shiftKey) {
						console.log('Now admin!');
						setChangeMomentPermission(!changeMomentPermission);
					}
					break;

				default:
					break;
			}
		},
		[changeMomentPermission, fetchMoment]
	);

	const toggleFullscreen = useCallback(() => {
		const elem = document.documentElement;
		if (!document.fullscreenElement) {
			elem.requestFullscreen().catch((err) => {
				console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
			});
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			}
		}
	}, []);

	useEffect(() => {
		// attach the event listener
		document.addEventListener('keydown', handleKeyPress);
		document.body.addEventListener('click', handleClick);
		// remove the event listener
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
			document.body.removeEventListener('click', handleClick);
		};
	}, [handleKeyPress, handleClick]);

	if (!moment) return <>Loading ....</>;
	return (
		<Container fluid={true} style={{ padding: '0.2vw 1vw' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<span>
					<img
						style={{ width: 'auto', height: '6vw' }}
						src="https://cdn.discordapp.com/attachments/557891960334057474/1025395311935488090/TradingWars.png"
						alt="logo"
					/>
					<span style={{ backgroundColor: 'white', borderRadius: '0.3vw', padding: '0vw 0.2vw' }}>{moment.id}</span>
					{!changeMomentPermission && <Badge color="success">&nbsp;</Badge>}
					{error && <Badge color="danger">Error</Badge>}
				</span>
				{moment.title && (
					<span
						style={{
							fontSize: '4vw',
							fontWeight: 'bold',
							color: 'white',
							backgroundColor: 'rgb(0 0 0 / 50%)',
							borderRadius: '25vw',
							padding: '0vw 2vw'
						}}
						onClick={toggleFullscreen}
					>
						{moment.title}
					</span>
				)}

				{moment.cost ? (
					<Badge style={{ color: 'white', fontSize: '2.5vw' }} color="dark">
						<span style={{ marginBottom: '20vw', verticalAlign: 'text-top' }}>Struts</span>&nbsp;
						<b style={{ fontSize: '4vw' }}>{moment.cost}</b>
					</Badge>
				) : (
					<>&nbsp;</>
				)}
			</div>

			<div style={{ borderTop: '0.1vw solid black', margin: '0.5vw 0' }} />
			{moment.photo ? (
				<div
					style={{
						marginTop: '3vw',
						textAlign: 'center',
						color: 'white',
						backgroundColor: 'rgb(0 0 0 / 50%)',
						borderRadius: '5vw',
						padding: '0vw 2vw'
					}}
				>
					<img alt="intro" src={moment.photo} style={{ margin: 'auto', objectFit: 'contain', width: '90vw', height: '70vh' }} />
				</div>
			) : (
				<Table productPrices={moment.productPrices} />
			)}

			<Row>
				{moment.line1 && (
					<Col md="4" style={{ backgroundColor: 'hsl(4 50% 50% / 1)' }}>
						<span style={{ fontSize: '3.3vw', filter: 'drop-shadow(0.1vw 0.2vw 0.2vw hsl(4 50% 5% / 70%))', color: 'white' }}>{moment.line1}</span>
					</Col>
				)}
				{moment.line2 && (
					<Col md="8" style={{ backgroundColor: 'hsl(0 0% 100% / 0.8)', fontWeight: 'bold' }}>
						<span style={{ fontSize: '2.5vw' }}>{moment.line2}</span>
						<br />
						<span style={{ fontSize: '2vw' }}>{moment.line3}</span>
					</Col>
				)}
			</Row>
		</Container>
	);
}
