import React, { useState, useCallback, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import socket from 'src/utils/socket';

export default function AlertModal() {
	// const [modal, setModal] = useState(true);
	const [data, setData] = useState();
	const close = () => setData(null);

	const answerHandler = useCallback(
		(p) => {
			const obj = {
				...data,
				text: p.text ?? null,
				buttons: p.buttons ?? null,
				image: p.image ?? null,
				title: p.title ?? null
			};
			setData(obj);
		},
		[data]
	);

	useEffect(() => {
		socket.on('modal', answerHandler);
		return () => socket.off(answerHandler);
	}, [answerHandler]);

	if (!data) return false;
	return (
		<Modal centered style={{ textAlign: 'center' }} isOpen={data.text} toggle={close}>
			{data.title && <ModalHeader>{data.title}</ModalHeader>}
			<ModalBody style={{ padding: '40px' }}>
				{data.image && (
					<img alt="" src={data.image} style={{ maxWidth: '100%', maxHeight: '500px', height: 'auto', width: 'auto', marginBottom: '20px' }} />
				)}
				<div style={{ whiteSpace: 'pre-line' }}>{data.text ?? null}</div>
			</ModalBody>
			{data.buttons && (
				<ModalFooter>
					{data.buttons.map((b, key) => (
						<Button outline key={key}>
							{b.text}
						</Button>
					))}
				</ModalFooter>
			)}
		</Modal>
	);
}
