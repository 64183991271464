import React, { useCallback, useEffect, useState } from 'react';
import socket from 'src/utils/socket';
import PhoneView from './PhoneView';
import { Button } from 'reactstrap';

import { ListGroup, ListGroupItem } from 'reactstrap';
export default function Select({ state }) {
	const [answerId, setAnswer] = useState('');
	const [answerVersion, setAnswerVersion] = useState(0);

	const answerHandler = useCallback(
		(p) => {
			if (p.selected_answer_version <= answerVersion) return;
			setAnswerVersion(p.selected_answer_version);
			setAnswer(p.selected_answer_id);
		},
		[answerVersion]
	);
	useEffect(() => {
		socket.on('answer_selected', answerHandler);
		return () => socket.off(answerHandler);
	}, [answerHandler]);

	return (
		<>
			<PhoneView background={state.roomBackground} cols={state.photo_based ? 8 : 6} fullSize={state.photo_based}>
				{state.taskImage && <img src={state.taskImage} alt="Task" style={{}} />}
				<h5 style={{ background: 'rgba(255,255,255,0.9)', borderRadius: '25px', padding: '10px', marginTop: '5px' }}>{state.taskTitle}</h5>
				<hr />
				<div style={{ textAlign: 'center', background: 'rgba(255,255,255,0.9)', borderRadius: '25px', padding: '10px', marginBottom: '20px' }}>
					<p style={{ whiteSpace: 'pre-line' }}>{state.taskDescription}</p>
				</div>
			</PhoneView>
			<PhoneView background={state.roomBackground} cols={state.photo_based ? 4 : 6} fullSize={state.photo_based}>
				<h4 style={{ background: 'rgba(255,255,255,0.9)', borderRadius: '25px', padding: '10px' }}>Possible Answers</h4>
				<ListGroup>
					{state.answers.map((answer, index) => {
						return (
							<ListGroupItem color={answer.answer_id === answerId && 'primary'} key={index}>
								{answer.answer_value}
							</ListGroupItem>
						);
					})}
				</ListGroup>
				<br />
				<Button disabled={!answerId}>Submit</Button>
			</PhoneView>
		</>
	);
}
