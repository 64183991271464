import React, { useRef } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, UncontrolledTooltip, FormFeedback } from 'reactstrap';

export default function AskDatetime(props) {
	const { title = 'Edit', description = '', placeholder = '', label = 'New value', submitLabel = 'Save', onSubmit = () => {}, value = '' } = props;
	const [open, setOpen] = React.useState(false);
	const [inputValue, setInputValue] = React.useState('');
	const [saving, setSaving] = React.useState(false);
	const [error, setError] = React.useState(false);
	const inputRef = useRef();
	const openDialog = () => {
		setInputValue(value);
		setError(false);
		setSaving(false);
		setOpen(true);
	};

	const handleSubmit = async () => {
		if (saving) return;
		setSaving(true);
		setError(false);
		let res = null;
		if (inputValue.trim() !== value) res = await onSubmit(inputValue.trim());
		setSaving(false);
		if (!res) return setOpen(false);
		setError(res);
		inputRef.current.focus();
	};

	const openButton = props.children ? (
		<span onClick={openDialog} style={{ cursor: 'pointer' }}>
			{props.children}
		</span>
	) : (
		<i id="askTextTooltip" style={{ cursor: 'pointer' }} onClick={openDialog} className="fa fa-pencil-alt ml-2" />
	);

	return (
		<>
			{openButton}
			{!props.children && (
				<UncontrolledTooltip placement="right" target="askTextTooltip" trigger="hover">
					Click to edit
				</UncontrolledTooltip>
			)}
			<Modal fullWidth isOpen={open} toggle={() => setOpen(!open)} aria-labelledby="form-dialog-title">
				<ModalHeader id="form-dialog-title">{title}</ModalHeader>
				<ModalBody>
					{description && <small>{description}</small>}

					<FormGroup>
						{label && <Label for="exampleEmail">{label}</Label>}
						<Input
							ref={inputRef}
							type={'datetime-local'}
							value={inputValue}
							onChange={(e) => {
								setInputValue(e.target.value);
								setError(false);
							}}
							invalid={!!error}
							placeholder={placeholder}
						/>
						{error && <FormFeedback style={{ fontSize: '1em' }}>{error}</FormFeedback>}
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => setOpen(false)} color="secondary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="primary">
						{saving ? <i className="mx-auto fa fa-cog fa-spin ml-2" /> : submitLabel}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
