let listeners = [];
const socket = {
	current: null,
	roomName: 'default',
	send: (eventName, data) => socket.current.send(JSON.stringify({ ...data, eventName })),
	on: (eventName, listener) => {
		listener.eventName = eventName;
		listeners.push(listener);
	},
	off: (listener) => (listeners = listeners.filter((l) => l !== listener)),
	joinRoom: (roomName) => {
		socket.roomName = roomName;
		if (socket.current.readyState === 1) socket.send(null, { join_room: roomName });
	}
};

const socket_connect = () => {
	socket.current = new WebSocket('wss://live.myactivityhub.net');
	socket.current.onmessage = (m) => {
		m = JSON.parse(m.data);
		listeners.forEach((l) => {
			if (l.eventName === m.eventName) l(m);
		});
	};
	socket.current.onclose = () => setTimeout(socket_connect, 3000);
	socket.current.onerror = (error) => console.log(`Socket error`, error.message);
	socket.current.onopen = () => socket.send(null, { join_room: socket.roomName });
};
socket_connect();

export default socket;
