import React, { useState, useEffect, useCallback } from 'react';

import { Container, Row, Col } from 'reactstrap';

import { PaperContainer, Circle, Layer } from '@psychobolt/react-paperjs';

const Shapes = () => <Circle center={[120, 50]} radius={35} fillColor="#00FF00" />;
const BoardEditor = (props) => {
	const { file } = props;

	const { backgroundImage } = file.data;

	const [elements, setElements] = useState([]);

	const addElement = useCallback(
		(elm) => {
			setElements([...elements, elm]);
		},
		[elements]
	);

	useEffect(() => {
		addElement({ type: 'text', text: 'hello', x: 50, y: 150 });
		//     window.addEventListener('resize', resize)

		addElement({ type: 'img', url: backgroundImage, x: 0, y: 0 });
	}, []);

	return (
		<Container className="noselect" fluid style={{ height: '100%', display: 'flex', flexFlow: 'column' }}>
			{/* <Row>
                {tool}
                <Button onClick={() => { setTool("text") }}>Text</Button>
                <Button onClick={() => { setTool("img") }}>Img</Button>
                <Button onClick={() => { setTool("rect") }}>Rect</Button>
            </Row> */}
			<Row style={{ flexGrow: 1 }}>
				<Col md={10} className="pr-0 pl-0">
					{/* <div ref={ratioRef} style={{
                        aspectRatio: "4/3",
                        width: "100%",
                        backgroundColor: "gold"
                    }}>

                        <canvas width={width} height={height} ref={canvasRef}
                            onClick={
                                (e) => {


                                    const canvas = canvasRef.current
                                    // const context = canvas.getContext('2d')
                                    var rect = canvas.getBoundingClientRect();
                                    let x = e.clientX - rect.left
                                    let y = e.clientY - rect.top

                                    if (tool === "text") {
                                        const { clientX, clientY } = e;
                                        console.log(e)
                                        addElement({ type: "text", text: "hello", x: x, y: y })
                                    }
                                    if (tool === "img") {

                                        addElement({ type: "img", url: "https://cdn.britannica.com/60/8160-050-08CCEABC/German-shepherd.jpg", x: x, y: y })
                                    }

                                }
                            }
                        />
                    </div> */}
					<PaperContainer>
						<Circle center={[80, 50]} radius={35} fillColor="red" />
						<Layer>
							<Shapes />
						</Layer>
					</PaperContainer>
				</Col>
				<Col md={2}>
					<Row style={{ border: '1px solid grey' }}>
						<Col>
							Properties
							<hr />
							<span>X: 0.0</span>
							<span>Y: 0.0</span>
						</Col>
					</Row>
					<Row style={{ border: '1px solid grey' }}>
						<Col>
							Layers
							<hr />
							<ul>
								{elements.map((elm, index) => {
									return <li>{elm.type}</li>;
								})}
							</ul>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default BoardEditor;
