import React, { useCallback, useEffect, useState } from 'react';
import socket from 'src/utils/socket';
import PhoneView from './PhoneView';
import { Button } from 'reactstrap';

export default function Photo({ state }) {
	const [image, setImage] = useState(null);

	const answerHandler = useCallback((p) => {
		p = JSON.parse(p);
		if (p.image_data === undefined) return;
		setImage(p.image_data);
	}, []);
	useEffect(() => {
		socket.on('answer_image', answerHandler);
		return () => socket.off(answerHandler);
	}, [answerHandler]);

	return (
		<PhoneView background={state.roomBackground}>
			<h5 style={{ background: 'rgba(255,255,255,0.9)', borderRadius: '25px', padding: '10px' }}>{state.taskTitle}</h5>
			<img src={image || state.taskImage} alt="Task" style={{ maxWidth: '100%', maxHeight: '30vh' }} />
			<hr />
			<div style={{ textAlign: 'center', background: 'rgba(255,255,255,0.9)', borderRadius: '25px', padding: '10px', marginBottom: '20px' }}>
				<p style={{ whiteSpace: 'pre-line' }}>{state.taskDescription}</p>
			</div>
			<Button disabled>Take a photo</Button>
		</PhoneView>
	);
}
