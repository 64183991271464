import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Folder from './Folder';
import FileContext from './FileContext';

// import { ContextMenu, /*MenuItem, */ ContextMenuTrigger } from 'react-contextmenu';

//https://codepen.io/sazzad/pen/gEEKQb
//https://codepen.io/Novut/pen/zZvpVJ

// import styled from 'styled-components';

/*
const ContextItem = styled(MenuItem)`
	padding-left: 1rem;
	padding-right: 1rem;

	&:hover {
		background-color: grey;
	}
`;
const ContextDivider = styled.hr`
	margin: 0;
`;*/

const FileTree = ({ className, style, data, initialOpen, actions, filter, setSelectedFile }) => {
	/*
	const handleClick = (e, data) => {
		console.log(selectedTreeFile);
		console.log(data.foo);
	};*/

	const [fileTreeData, setFileTreeData] = useState(null);

	const [selectedTreeFile, setSelectedTreeFile] = useState(null);
	const [contextData, setContextData] = useState(null);

	return (
		<div style={style} className={`FileTree noselect ${className ?? ''}`}>
			{/* <ContextMenuTrigger id="FileTreeContextMenuID"> */}
			<FileContext.Provider
				value={{
					selectedFile: selectedTreeFile,
					setSelectedFile: (e) => {
						console.log(e);
						setSelectedTreeFile(e);
						setSelectedFile(e);
					},
					setContextData: (e) => {
						setContextData(e);
					},
					data: fileTreeData,
					setData: (e) => {
						setFileTreeData(e);
					}
				}}
			>
				{data.map((data, index) => {
					// console.log(data?.actions)
					return <Folder key={index} {...data} initialOpen={initialOpen} actions={{ ...actions, ...data?.actions }} depth={0} filter={filter} />;
				})}
			</FileContext.Provider>
			{/* </ContextMenuTrigger> */}
			{/* 					
			<ContextMenu id="FileTreeContextMenuID" style={{ border: 'solid 1px grey', backgroundColor: 'white' }}>
				{contextData}
			</ContextMenu> */}
		</div>
	);
};

FileTree.propTypes = {
	data: PropTypes.array.isRequired
};

export default FileTree;
