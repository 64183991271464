import React from 'react';
import { Table } from 'reactstrap';
export default function TradingTable(props) {
	const { productPrices } = props;

	const replaceZero = (value) => {
		if (value === 0) return '----';
		return value;
	};

	return (
		<Table
			size="sm"
			striped
			responsive
			dark
			className="table-pad-sm"
			style={{ fontSize: '3.5vw', fontWeight: 'bold', textAlign: 'center', backgroundColor: 'rgb(52 58 64 / 65%)', marginBottom: '2vw' }}
		>
			<thead>
				<tr>
					<th></th>
					<th>Advert</th>
					<th>Logistics</th>
					<th>Product</th>
					<th>Payout</th>
				</tr>
			</thead>
			<tbody>
				{productPrices.map((pp, index) => (
					<tr key={index}>
						<th style={{ textAlign: 'left', paddingLeft: '2.5vw' }}>{pp.product.name}</th>
						<td>
							<Pill bgColor={pp.product.background} color={pp.product.color}>
								{replaceZero(pp.advert)}
							</Pill>
						</td>
						<td>
							<Pill bgColor={pp.product.background} color={pp.product.color}>
								{replaceZero(pp.logistics)}
							</Pill>
						</td>
						<td>
							<Pill bgColor={pp.product.background} color={pp.product.color}>
								{replaceZero(pp.productCost)}
							</Pill>
						</td>
						<td>
							<Pill bgColor={pp.product.background} color={pp.product.color}>
								{replaceZero(pp.payout)}
							</Pill>
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
}

function Pill(props) {
	const { color, bgColor, children } = props;
	return (
		<span
			style={{
				backgroundColor: bgColor,
				color: color,
				borderRadius: '25vw',
				padding: '0vw 1vw'
			}}
			children={children}
		/>
	);
}
