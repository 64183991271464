import { React, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
	Container,
	Row,
	Col,
	Button,
	ListGroup,
	ListGroupItem,
	Spinner,
	UncontrolledTooltip,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap';
import axios from 'src/utils/axios';
import { AskSelect } from 'src/components/questions';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

import CreateActivityModal from './components/CreateActivityModal';

import { useApp } from 'src/appContext';

const Home = () => {
	const navigate = useHistory();
	const [events, setEvents] = useState([]);
	const [activities, setActivities] = useState([]);

	const { user, logout, settings, setSettings } = useApp();

	const [archiveSort, setArchiveSort] = useState(settings?.home.archiveSort ?? true);

	const [createActivityModal, setCreateActivityModal] = useState(false);
	const createActivityModalToggle = () => setCreateActivityModal(!createActivityModal);

	const fetchEvents = useCallback(async () => {
		try {
			var res = await API.get('api', '/event/all');
			console.log(res);
			setEvents(res);
		} catch (error) {
			return error;
		}
	}, []);
	useEffect(fetchEvents, [fetchEvents]);

	const fetchActivities = useCallback(async () => {
		try {
			const response = await axios.get(`/activity`, { headers: { Authorization: user.signInUserSession.accessToken.jwtToken } });
			// console.log(response.data)
			setActivities(response.data);
		} catch (error) {
			alert('Failed to get activity');
			console.error(error);
		}
	}, []);
	useEffect(fetchActivities, [fetchActivities]);

	const createEvent = async (activity_id) => {
		try {
			await axios.post('/event/create', {
				activity_id
			});
			await fetchEvents();
		} catch (error) {
			return error;
		}
	};

	// useEffect(() => {
	// 	getEvents();
	// 	getActivities();
	// }, []);

	const changeArchiveSort = (state) => {
		setArchiveSort(state);

		let newSettings = settings ?? { home: {} };
		if (newSettings.home == null) newSettings.home = {};

		newSettings.home.archiveSort = state;
		setSettings(newSettings);
	};

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Container>
			<h3>
				{`Welcome ${user?.name}`}
				<Button color="warning" onClick={() => logout()} outline className="float-right mt-2">
					<i className="fa fa-arrow-left" aria-hidden="true"></i> Log out
				</Button>
				<Link to={`/settings`}>
					<Button color="primary" outline className="float-right mt-2">
						<i className="fa fa-cog" aria-hidden="true"></i> Settings
					</Button>
				</Link>
				<Dropdown className="float-right mt-2" isOpen={dropdownOpen} toggle={toggle}>
					<DropdownToggle caret>Extra</DropdownToggle>
					<DropdownMenu>
						<DropdownItem header>Trading Wars</DropdownItem>
						<DropdownItem onClick={() => navigate.push('/trading-wars/editor')}>Editor</DropdownItem>
						<DropdownItem onClick={() => navigate.push('/trading-wars')}>Presentation</DropdownItem>
						<DropdownItem divider />
					</DropdownMenu>
				</Dropdown>
			</h3>
			<hr />
			<Row>
				<Col md={6}>
					<Row>
						<Col lg={7}>
							<h3>Events Manager&nbsp;</h3>
						</Col>
						<Col lg={5}>
							<AskSelect
								onSubmit={async (v) => await createEvent(v)}
								values={activities.map((a) => {
									return { value: a.activity_id, text: a.activity_name };
								})}
							>
								<Button color="primary" outline>
									<i className="fa fa-plus" aria-hidden="true"></i> Create Event
								</Button>
							</AskSelect>

							<i
								id="archiveSortButton"
								style={{ color: archiveSort ? 'black' : 'red' }}
								onClick={() => {
									changeArchiveSort(!archiveSort);
								}}
								className="fa fa-archive float-right"
							></i>

							<UncontrolledTooltip flip target={`archiveSortButton`}>
								{archiveSort ? 'Show all' : 'Show recent'}
							</UncontrolledTooltip>
						</Col>
					</Row>

					{!events.length && <Spinner />}
					<ListGroup>
						{events.map((ev) => {
							if (!ev.archived || !archiveSort) return <EventEntry event={ev} key={ev.event_id} reloadData={fetchEvents} />;
							return false;
						})}
					</ListGroup>
				</Col>
				<Col md={6}>
					<h3>
						Activity Designer&nbsp;
						<Button color="warning" outline className="ml-auto" onClick={createActivityModalToggle}>
							<i className="fa fa-plus" aria-hidden="true"></i> New Activity
						</Button>
					</h3>

					{!activities.length && <Spinner />}
					<ListGroup>
						{activities.map((ev) => {
							return <ActivityEntry activity={ev} key={ev.activity_id} />;
						})}
					</ListGroup>
				</Col>
			</Row>

			<CreateActivityModal isOpen={createActivityModal} toggle={createActivityModalToggle} fetchData={fetchActivities} />
		</Container>
	);
};

const EventEntry = ({ event, reloadData }) => {
	const archiveEvent = async (event_id, state) => {
		console.log('test ' + event_id);
		try {
			await axios.post(`/event/${event_id}/archive`, { state });
			await reloadData();
		} catch (error) {
			return error;
		}
	};

	const overDate = new Date(event.event_start * 1000) < new Date();

	return (
		<ListGroupItem>
			<Link to={`/event/${event.event_id}`}>
				<i className="far fa-star fa-fw"></i>&nbsp;
				{event.event_name}
			</Link>
			<span className="float-right">
				<button
					id={`eventEntryArchiveButton-${event.event_id}`}
					onClick={() => {
						archiveEvent(event.event_id, event.archived ? 0 : 1);
					}}
					className="archiveEvent float-right btn"
				>
					<i style={{ color: event.archived ? 'red' : 'black' }} className="fa fa-archive"></i>
				</button>
			</span>
			<UncontrolledTooltip flip target={`eventEntryArchiveButton-${event.event_id}`}>
				{event.archived ? 'Unarchive' : 'Archive'}
			</UncontrolledTooltip>

			{event.event_start && (
				<div style={{ color: overDate ? 'orange' : 'black' }}>
					<i className="far fa-calendar-alt fa-fw"></i>&nbsp;
					{new Date(event.event_start * 1000).toDateString()}
				</div>
			)}
			<div>
				<i className="far fa-map fa-fw"></i>&nbsp;{event.activity_name}
			</div>
		</ListGroupItem>
	);
};

const ActivityEntry = ({ activity }) => {
	return (
		<ListGroupItem>
			<Link to={`/activity/${activity.activity_id}`}>
				{' '}
				<i className="fas fa-gamepad"></i> {activity.activity_name}{' '}
			</Link>
			<Link className="float-right" to={`/activity/editor/${activity.activity_id}`}>
				<i className="fas fa-edit" />
			</Link>
		</ListGroupItem>
	);
};

export default Home;
