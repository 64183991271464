var config = {
	apiLocation: process.env.REACT_APP_BACKEND_URL,
	cognito: {
		REGION: 'eu-west-2',
		USER_POOL_ID: 'eu-west-2_dNAbNiJiv', //Cognito > user pool overview
		APP_CLIENT_ID: '6sg3hjn0e2jnbr21min9cugfhb' //Cognito > user pool >App integration
		//   IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,	//used for other auth logins like FB, google
	}
};

module.exports = config;
