import React from 'react';
import { Card, Col } from 'reactstrap';

export default function PhoneView({ children, noPadding, background, fullSize, cols = 6 }) {
	const aspectRatio = fullSize ? '' : '9 / 16';
	return (
		<Col md={cols}>
			<Card
				className="mt-2"
				style={{
					maxHeight: '90vh',
					aspectRatio,
					padding: noPadding ? '' : '16px',
					backgroundImage: background ? `url("${background}")` : '',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					border: '1px solid black',
					borderRadius: '12px',
					overflow: 'hidden',
					boxShadow: '0px 0px 15px 1px'
				}}
			>
				{children}
			</Card>
		</Col>
	);
}
