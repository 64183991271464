import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router';
import _ from 'lodash';
import { Container, Row, Col, Card, CardBody, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Spinner as Spin } from 'reactstrap';

import axios from 'src/utils/axios.js';
import Spinner from 'src/components/spinner';
import { Link } from 'react-router-dom';

export default function RoomConfigure() {
	const { id } = useParams();

	const [numbersActive, setNumbersActive] = useState(false);
	const [icons, setIcons] = useState(null);
	const [room, setRoom] = useState(null);
	const [selectedTask, setSelectedTask] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [settingImg, setSettingImg] = useState(false);
	const [saving, setSaving] = useState(false);

	const loadRoom = useCallback(async () => {
		try {
			var res = await axios.get(`/room/${id}`);
			setRoom(res.data);

			res = await axios.get(`/activity/${res.data.activity_id}/list-images`);
			setIcons(res.data);
		} catch (error) {
			console.error(error);
		}
	}, [id]);

	// const loadIcons = useCallback(async () => {
	// 	if (!room) return;
	// 	console.log('check');
	// 	try {
	// 		var res = await axios.get(`/activity/${room.activity_id}/list-images`, {
	// 			params: { activityId: room.activity_id }
	// 		});
	// 		setIcons(res.data);
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// }, [room]);

	// useEffect(() => {
	// 	loadIcons();
	// }, [room]);

	useEffect(() => {
		loadRoom();
	}, [loadRoom]);

	const changePosition = (id, x, y) => {
		if (!id) return;

		let newTasks = room.tasks;

		let index = newTasks.findIndex((e) => e.task_id === id);

		newTasks[index].x = x;
		newTasks[index].y = y;
		newTasks[index].updated = true;

		setRoom({ ...room, tasks: newTasks });
	};

	const saveTasks = async () => {
		if (!room) return;
		setSaving(true);
		let tasks = room.tasks.filter((e) => e.updated);
		console.log(tasks);

		tasks.forEach(async (task) => {
			await axios.post(`/task/${task.task_id}/update`, { pos_x: task.x, pos_y: task.y, activity_id: room.activity_id });
		});

		await loadRoom();
		setSaving(false);
	};
	const debouncedSaveTasks = _.debounce(saveTasks, 300);

	return (
		<>
			<Container className="noselect" fluid style={{ padding: 0 }}>
				<Row noGutters>
					<Col md={1}>
						<Link to={`/room/${id}`}>
							<Button color="secondary" outline>
								<i className="fa fa-arrow-left" aria-hidden="true"></i> Return
							</Button>
						</Link>
					</Col>
					<Col md={6}>
						{/* <h1 className="mt-2">{state?.game_name}</h1>
					<hr /> */}
						<Card style={{ maxHeight: '90vh', aspectRatio: '9 / 16' }}>
							{room && !saving ? (
								<MainScreen
									numbersActive={numbersActive}
									image={room?.background_image_url}
									tasks={room?.tasks}
									selectedTask={selectedTask}
									setLocation={changePosition}
									onSave={debouncedSaveTasks}
								/>
							) : (
								<Spinner />
							)}
						</Card>
					</Col>
					<Col md={5}>
						<Card>
							<CardBody>
								<h3>
									Tasks{' '}
									<Button
										onClick={() => {
											setNumbersActive(!numbersActive);
										}}
									>
										Show IDs
									</Button>
								</h3>

								<Table hover responsive size="sm">
									<thead>
										<tr>
											<th>#</th>
											<th>Task</th>
											<th>type</th>
											<th>x</th>
											<th>y</th>
											<th>Icon</th>
											<th>#</th>
										</tr>
									</thead>
									<tbody>
										{room?.tasks.map((t) => {
											return (
												<tr key={t.task_id} className={selectedTask?.task_id === t.task_id ? 'table-active' : ''}>
													<td>{t.task_id}</td>
													<td>{t.task_title}</td>
													<td>{t.type_name}</td>
													<td>{(t.x * 100).toFixed(1)}%</td>
													<td>{(t.y * 100).toFixed(1)}%</td>
													<td>{t.task_icon ? 'Y' : 'N'}</td>

													<td
														onClick={() => {
															setShowModal(true);
															setSelectedTask(t);
														}}
													>
														<i className="fa fa-pencil-alt ml-2" />
													</td>
												</tr>
											);
										})}
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<Modal
				toggle={() => {
					setShowModal(false);
				}}
				isOpen={showModal}
				style={{ position: 'relative' }}
			>
				{settingImg && (
					<div
						style={{
							left: 0,
							right: 0,
							top: 0,
							bottom: 0,
							position: 'absolute',
							zIndex: 1500,
							backgroundColor: 'rgba(0, 0, 0, 0.3)',
							textAlign: 'center'
						}}
					>
						<div
							style={{
								transform: 'translateY(200%)'
							}}
						>
							<Spin size="small" color="dark" />
							<br />
							Loading
						</div>
					</div>
				)}

				<ModalHeader
					toggle={() => {
						setShowModal(false);
					}}
				>
					Edit: <span>{selectedTask?.task_title}</span>
				</ModalHeader>
				<ModalBody>
					<h3>Icon</h3>

					{/* <Input
						ref={inputRef}
						type={type || "file"}
						name="text"
						onChange={(e) => {
							setFile(e.target.files[0]);
							setError(false);
						}}
						invalid={!!error}
						placeholder={placeholder}
					/> */}

					<Table hover responsive size="sm">
						<thead>
							<tr>
								<th>Title</th>
								<th>image</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{icons?.map((i, index) => {
								return (
									<tr key={index} className={selectedTask?.task_icon === i.s3_object_id ? 'table-active' : ''}>
										<td>{i.title}</td>
										<td>
											<img src={i.image_url} alt="icon" style={{ maxHeight: 'auto', maxWidth: '50px' }} />
										</td>
										<td>
											{selectedTask?.task_icon === i.s3_object_id ? (
												<>Selected</>
											) : (
												<Button
													size="sm"
													onClick={async () => {
														setSettingImg(true);
														setSelectedTask(i);
														console.log(selectedTask);
														await axios.post(`/task/${selectedTask.task_id}/update`, {
															task_icon: i.s3_object_id,
															activity_id: room.activity_id
														});
														setSettingImg(false);
														setShowModal(false);
													}}
												>
													Use
												</Button>
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={() => {
							setShowModal(false);
						}}
					>
						Cancel
					</Button>
					<Button
						color="primary"
						onClick={() => {
							setShowModal(false);
							loadRoom();
						}}
					>
						Save
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}

const MainScreen = ({ numbersActive, image, tasks, setLocation, onSave }) => {
	const calcLocation = (x, y) => {
		return [x * (size?.w ?? 0.5), y * (size?.h ?? 0.5)];
	};
	const [size, setSize] = useState(null);
	const [selectedTask, setSelectedTask] = useState(null);

	const imageRef = useRef();

	function handleResize() {
		if (imageRef) setSize({ w: imageRef.current?.clientWidth, h: imageRef.current?.clientHeight });
	}

	useEffect(() => {
		handleResize();
	}, [imageRef]);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return (_) => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div
			ref={imageRef}
			style={{
				height: '100%',
				width: '100%',
				backgroundImage: `url("${image ?? ''}")`,
				backgroundSize: 'cover'
			}}
			className="noselect"
		>
			<div
				style={{
					position: 'absolute',
					left: 0,
					right: 0,
					top: 0,
					bottom: 0
				}}
				// onClick={(e) => {
				// 	const { x, y, width, height } = imageRef?.current?.getBoundingClientRect();
				// 	setLocation({ id: 1, x: e.clientX - x, y: e.clientY - y, w: width, h: height })
				// }}
				onMouseUp={() => {
					setSelectedTask(null);
					onSave();
				}}
				onMouseMove={(e) => {
					if (!selectedTask) return;
					const { x, y, width, height } = imageRef?.current?.getBoundingClientRect();

					var task = tasks.find((task) => task.task_id === selectedTask);

					let iconWidth = task.width * width;
					let iconHeight = task.height * height;

					let xpos = (e.clientX - iconWidth / 2 - x) / width;
					let ypos = (e.clientY - iconHeight / 2 - y) / height;
					let newx = Math.max(0.0, Math.min(0.95, xpos));
					let newy = Math.max(0.0, Math.min(0.95, ypos));

					setLocation(selectedTask, newx, newy);
				}}
			>
				{tasks?.map((task, index) => {
					const [x, y] = calcLocation(task.x, task.y);
					const [w, h] = calcLocation(task.width, task.height);
					return (
						<div
							key={index}
							style={{
								position: 'absolute',
								zIndex: 5,
								left: x,
								top: y
							}}
							onMouseDown={() => {
								setSelectedTask(task.task_id);
							}}
							onMouseUp={() => {
								setSelectedTask(null);
								onSave();
							}}
						>
							<div
								style={{
									backgroundColor: selectedTask === task.task_id ? 'yellow' : 'grey',
									borderRadius: 100,
									width: 30,
									height: 30,
									alignItems: 'center',
									justifyContent: 'center',
									textAlign: 'center',
									verticalAlign: 'middle',
									display: 'table-cell'
								}}
							>
								{numbersActive ? (
									task.task_id
								) : task?.task_icon_url ? (
									<img draggable={false} alt="icon" src={task?.task_icon_url} style={{ width: w, height: h }} />
								) : (
									task.task_title
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
