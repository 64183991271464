import React from 'react';
import ReactPlayer from 'react-player';

const ImageViewer = (props) => {
	const { file } = props;

	return (
		<div style={{ height: '100%', width: '100%' }}>
			<ReactPlayer url={file.data.url} />
		</div>
	);
};

export default ImageViewer;
