import React, { useState, useEffect, useCallback } from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import axios from 'src/utils/axios';
import pAxios from 'axios';

const thumbsContainer = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginTop: 16,
	padding: 20
};

const thumb = {
	position: 'relative',
	display: 'inline-flex',
	borderRadius: 2,
	border: '1px solid #eaeaea',
	marginBottom: 8,
	marginRight: 8,
	width: 100,
	height: 100,
	padding: 4,
	boxSizing: 'border-box'
};

const thumbInner = {
	display: 'flex',
	minWidth: 0,
	overflow: 'hidden'
};

const img = {
	display: 'block',
	width: 'auto',
	height: '100%'
};

const getColor = (props) => {
	if (props.isDragAccept) {
		return '#00e676';
	}
	if (props.isDragReject) {
		return '#ff1744';
	}
	if (props.isDragActive) {
		return '#2196f3';
	}
	return '#eeeeee';
};

const DropContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-width: 2px;
	border-radius: 2px;
	border-color: ${(props) => getColor(props)};
	border-style: dashed;
	background-color: #fafafa;
	color: #bdbdbd;
	outline: none;
	transition: border 0.24s ease-in-out;
`;

export default function AskFiles(props) {
	const { onUpload, onDone } = props;
	const [files, setFiles] = useState([]);
	const [isUploading, setIsUploading] = useState(false);
	const [open, setOpen] = useState(false);

	const onDrop = useCallback(
		(acceptedFiles) => {
			let newFiles = acceptedFiles.map((file) => {
				if (file?.type === 'image/png')
					return Object.assign(file, {
						preview: URL.createObjectURL(file)
					});
				return file;
			});
			setFiles([...files, ...newFiles]);
		},
		[files]
	);

	const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept } = useDropzone({
		onDrop
	});

	const openButton = props.children ? (
		<span onClick={() => setOpen(true)}>{props.children}</span>
	) : (
		<i id="askConfirmTooltip" onClick={() => setOpen(true)} className="fa fa-pencil-alt" />
	);

	const thumbs = files.map((file) => (
		<div style={thumb} key={file.name}>
			<div style={thumbInner}>{file.type === 'image/png' ? <img alt="" src={file.preview} style={img} /> : <small>{file.name}</small>}</div>
		</div>
	));

	const startUpload = async () => {
		setIsUploading(true);
		try {
			var instance = pAxios.create();
			delete instance.defaults.headers.common['Authorization'];
			var _files = [];
			files.forEach((f) => {
				_files.push({
					name: f['name'],
					type: f['type'],
					size: f['size']
				});
			});

			var { data } = await axios.post(`/s3/signed-url`, {
				files: _files
			});

			console.log('data');
			console.log(data);
			for (let i = 0; i < data.length; i++) {
				try {
					const { url } = data[i];
					await instance.put(url, files[i], {
						headers: {
							'Content-Type': files[i].type
						}
					});
					console.log('instance');
					await onUpload(data[i]);
					files[i].completed = true;
					setFiles([...files]);
				} catch (error) {
					console.error(error);
				}
			}
			setIsUploading(false);
			setFiles([]);
			await onDone();
			setOpen(false);
		} catch (error) {
			console.error(error);
			setIsUploading(false);
		}
	};

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		},
		[files]
	);
	// console.log(files)

	return (
		<div>
			{openButton}
			<Modal isOpen={open} toggle={() => setOpen(!open)}>
				<ModalHeader toggle={() => setOpen(!open)}>File upload</ModalHeader>
				<ModalBody>
					<div>
						<DropContainer {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
							<input {...getInputProps()} />
							<p>Click to add files or drag 'n' drop them here </p>
						</DropContainer>
						<aside style={thumbsContainer}>{thumbs}</aside>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={() => setOpen(!open)}>
						Cancel
					</Button>
					<Button
						color="primary"
						onClick={() => {
							startUpload();
						}}
					>
						{isUploading ? <i className="mx-auto fa fa-cog fa-spin ml-2" /> : 'Upload'}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}
