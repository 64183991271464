import { useState, useCallback, useEffect } from 'react';
import { Container, Row, Col, Table, Spinner, Button, Card, CardBody } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import graphql from 'src/utils/graphql';
import { AskText, AskSelect, AskConfirm } from 'src/components/questions';
export default function EditMoment() {
	const [moment, setMoment] = useState(null);
	const [unusedProducts, setUnusedProducts] = useState(null);
	const { id } = useParams();

	const fetchMoment = useCallback(async () => {
		try {
			var { moment, products } = await graphql({
				query: `
				{
					moment(id: ${id}) {
						id
						title,
						duration
						cost
						line1
						line2
						line3
						productPrices {
							id
							product {
								id
								name
							}
							advert
							logistics
							productCost
							payout
						}
						photo
					}
					products {
						id
						name
					} 
				}`
			});

			setMoment(moment);
			setUnusedProducts(products.filter((p) => !moment.productPrices.find((pp) => pp.product.id === p.id)));
		} catch (error) {
			alert(error.message);
		}
	}, [id]);

	const updateProductMoment = async (input) => {
		try {
			await graphql({
				query: `
				mutation($input: ProductMomentInput!) {
					saveProductMoment(input: $input)
				}`,
				variables: {
					input
				}
			});
			fetchMoment();
		} catch (error) {
			alert(error.message);
		}
	};

	const updateMoment = async (input) => {
		try {
			await graphql({
				query: `
				mutation($input: MomentInput!) {
					saveMoment(input: $input)
				}`,
				variables: {
					input
				}
			});
			fetchMoment();
		} catch (error) {
			alert(error.message);
		}
	};

	const deleteProductMoment = async (id) => {
		try {
			await graphql({
				query: `
				mutation($id: ID!) {
					deleteProductMoment(id: $id)
				}`,
				variables: {
					id
				}
			});
			fetchMoment();
		} catch (error) {
			alert(error.message);
		}
	};

	useEffect(() => {
		fetchMoment();
	}, [fetchMoment]);

	return (
		<Container>
			<h3>
				Moment Editor - {id}
				<Link to={'../'}>
					<Button className="float-right mt-1">Back to editor</Button>
				</Link>
			</h3>
			<hr />
			{!moment && <Spinner />}
			{moment && (
				<>
					<Card style={{ fontSize: '1.2em', marginBottom: '10px' }}>
						<CardBody>
							<div>
								<b>Title:</b> {moment.title}{' '}
								<AskText
									value={moment.title}
									onSubmit={(value) =>
										updateMoment({
											id: id,
											title: value
										})
									}
								/>
							</div>
							<div>
								<b>Duration (Seconds) :</b> {moment.duration}{' '}
								<AskText
									value={moment.duration}
									onSubmit={(value) =>
										updateMoment({
											id: id,
											duration: Number(value)
										})
									}
								/>
							</div>
							<div>
								<b>Strut Cost:</b> {moment.cost}{' '}
								<AskText
									value={moment.cost}
									onSubmit={(value) =>
										updateMoment({
											id: id,
											cost: Number(value)
										})
									}
								/>
							</div>
							<br />
							<div>
								<b>Alert Title:</b> {moment.line1}{' '}
								<AskText
									value={moment.line1}
									onSubmit={(value) =>
										updateMoment({
											id: id,
											line1: value
										})
									}
								/>
							</div>
							<div>
								<b>Article Title:</b> {moment.line2}{' '}
								<AskText
									value={moment.line2}
									onSubmit={(value) =>
										updateMoment({
											id: id,
											line2: value
										})
									}
								/>
							</div>
							<div>
								<b>Article Text:</b> {moment.line3}{' '}
								<AskText
									value={moment.line3}
									onSubmit={(value) =>
										updateMoment({
											id: id,
											line3: value
										})
									}
								/>
							</div>
							<br />
							<div>
								<b>Misc Photo URL:</b> {moment.photo}{' '}
								<AskText
									value={moment.photo}
									onSubmit={(value) =>
										updateMoment({
											id: id,
											photo: value
										})
									}
								/>
							</div>
						</CardBody>
					</Card>
					{!moment.photo ? (
						<Table bordered responsive dark>
							<thead>
								<tr>
									<th></th>
									<th>Advert</th>
									<th>Logistics</th>
									<th>Product</th>
									<th>Payout</th>
								</tr>
							</thead>
							<tbody>
								{moment.productPrices.map((p, index) => (
									<tr key={index}>
										<th>{p.product.name}</th>
										<td>
											{p.advert}{' '}
											<AskText
												value={p.advert}
												onSubmit={(value) =>
													updateProductMoment({
														id: p.id,
														advert: Number(value)
													})
												}
											/>
										</td>
										<td>
											{p.logistics}{' '}
											<AskText
												value={p.logistics}
												onSubmit={(value) =>
													updateProductMoment({
														id: p.id,
														logistics: Number(value)
													})
												}
											/>
										</td>
										<td>
											{p.productCost}{' '}
											<AskText
												value={p.productCost}
												onSubmit={(value) =>
													updateProductMoment({
														id: p.id,
														productCost: Number(value)
													})
												}
											/>
										</td>
										<td>
											{p.payout}{' '}
											<AskText
												value={p.payout}
												onSubmit={(value) =>
													updateProductMoment({
														id: p.id,
														payout: Number(value)
													})
												}
											/>
										</td>
										<td>
											<AskConfirm description="Delete this row?" onSubmit={() => deleteProductMoment(p.id)}>
												<Button size="sm" color="danger">
													<i className="fa fa-times" />
												</Button>
											</AskConfirm>
										</td>
									</tr>
								))}
								<tr>
									<td colspan="5" />
								</tr>
								{unusedProducts &&
									unusedProducts.map((p, index) => (
										<tr key={index}>
											<th>{p.name}</th>
											<td colspan="4"></td>
											<td>
												<Button
													onClick={() => {
														updateProductMoment({
															momentId: id,
															productId: p.id
														});
													}}
													size="sm"
													color="success"
												>
													<i className="fa fa-plus" />
												</Button>
											</td>
										</tr>
									))}
							</tbody>
						</Table>
					) : (
						<Button
							color="danger"
							onClick={() =>
								updateMoment({
									id: id,
									photo: ''
								})
							}
						>
							Remove Image and show table
						</Button>
					)}
				</>
			)}
		</Container>
	);
}
