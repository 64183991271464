import axios from 'src/utils/axios';
import { React, useState } from 'react';
import { Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';

const Home = ({ isOpen, toggle, fetchData }) => {
	const [waiting, isWaiting] = useState(false);
	const [name, setName] = useState('');

	const createActivity = async () => {
		console.log('test');
		isWaiting(true);
		try {
			await axios.post(`/activity/create`, { name: name });

			isWaiting(false);
			fetchData();
			toggle();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<ModalHeader toggle={toggle}>Create new activity</ModalHeader>
			<ModalBody>
				<Form>
					<FormGroup row>
						<Label for="activityName" sm={2}>
							Name
						</Label>
						<Col sm={10}>
							<Input
								type="text"
								id="activityName"
								value={name}
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
						</Col>
					</FormGroup>
				</Form>
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={toggle}>
					Cancel
				</Button>
				<Button color="primary" onClick={createActivity}>
					{waiting ? <i className="mx-auto fa fa-cog fa-spin ml-2" /> : 'Create activity'}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default Home;
