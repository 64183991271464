import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { Container, Row, Col } from 'reactstrap';
import axios from 'src/utils/axios.js';
import Spinner from 'src/components/spinner';
import { MainScreen, Photo, Question, Select } from './components';
import AlertModal from './components/AlertModal';
import socket from 'src/utils/socket';

export default function Activity() {
	const { id } = useParams();
	const [state, setState] = useState(null);
	const [captain, setCaptain] = useState(false);
	const [captainTimeout, setCaptainTimeout] = useState();
	const [timeLeft, setTimeLeft] = useState('00:00');

	const onHeartbeat = useCallback(
		(p) => {
			clearTimeout(captainTimeout);
			setCaptain(true);
			setCaptainTimeout(setTimeout(() => setCaptain(false), 10000));
		},
		[captainTimeout]
	);

	useEffect(() => {
		socket.on('heartbeat', onHeartbeat);
		return () => socket.off(onHeartbeat);
	}, [onHeartbeat]);

	useEffect(() => {
		if (!id) return;
		socket.joinRoom(id);
	}, [id]);

	const loadActivity = useCallback(async () => {
		try {
			const data = (await axios.get(`/app/${id}`)).data;
			setState(data);
		} catch (error) {
			console.error(error);
		}
	}, [id]);

	useEffect(() => {
		document.title = 'Presenter';
		loadActivity();
	}, [loadActivity]);

	useEffect(() => {
		const interval = setInterval(loadActivity, 5000);

		return (_) => {
			clearInterval(interval);
		};
	}, [loadActivity]);

	useEffect(() => {
		if (!state?.event_end) return false;
		const interval = setInterval(() => {
			let time = Math.max(state.event_end - Math.floor(Date.now() / 1000), 0);
			let string = time >= 3600 ? ('0' + Math.floor(time / 60 / 60)).substr(-2) + ':' : '';
			string += ('0' + (Math.floor(time / 60) % 60)).substr(-2) + ':';
			string += ('0' + (time % 60)).substr(-2);
			setTimeLeft(string);
		}, 1000);
		return () => clearInterval(interval);
	}, [state?.event_end]);

	if (!state) return <Spinner />;

	return (
		<Container
			fluid
			style={
				state.presenter_background && {
					padding: 0,
					backgroundImage: `url('${state.presenter_background_url}')`,
					backgroundSize: 'cover',
					backgroundPosition: 'center top',
					backgroundAttachment: 'fixed',
					backgroundRepeat: 'no-repeat',
					height: '100vh',
					paddingTop: '1vh'
				}
			}
		>		<div
			style={{
				position: 'absolute',
				zIndex: 1000,
				right: '2vw',
				top: '2vw',
				background: 'rgba(255,255,255,0.4)',
				padding: '0.5vw',
				borderRadius: '0.5vw'
			}}
		>
				<img src={state.brand_small_url} style={{ maxWidth: '13vw', maxHeight: '13vh' }} alt=""></img>
			</div>
			<Row className="justify-content-center" style={{ maxWidth: '90%', margin: 'auto', minHeight: '90vh', alignItems: 'center' }}>
				{!captain ? (
					<Col md={4}>
						{state.welcome_text && (
							<div
								style={{
									textAlign: 'center',
									background: 'rgba(255,255,255,0.7)',
									borderRadius: '25px',
									padding: '24px',
									marginTop: '20vh',
									wordWrap: 'break-word',
									whiteSpace: 'pre-line'
								}}
							>
								{state.welcome_text}
							</div>
						)}

						<div
							style={{
								textAlign: 'center',
								background: 'rgba(255,255,255,0.7)',
								borderRadius: '25px',
								padding: '24px',
								marginTop: state.welcome_text ? '2em' : '18em'
							}}
						>
							<img
								style={{ backgroundColor: 'white', borderRadius: '1vw', width: '12vw', marginBottom: '1vw' }}
								alt="Gamecode"
								src={'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=' + encodeURI(`https://app.myactivityhub.net/?gamecode=${id}`)}
							/>
							<br />
							Scan QR code, or go to:
							<br />
							<b>{`https://app.myactivityhub.net/?gamecode=${id}`}</b>
						</div>
					</Col>
				) : (
					<>
						{state.gameState === 'room' && <MainScreen state={state} />}
						{state.gameState === 'task' && state.taskType === 'photo' && <Photo state={state} />}
						{state.gameState === 'task' && state.taskType === 'question' && <Question state={state} />}
						{state.gameState === 'task' && state.taskType === 'select' && <Select state={state} />}
					</>
				)}
			</Row>
			<AlertModal />
			<div
				style={{
					position: 'absolute',
					bottom: '2vh',
					left: '2vh',
					backgroundColor: '#eee',
					borderRadius: '5px',
					fontWeight: 'bold',
					fontSize: '1.3em',
					padding: '1px 6px',
					border: '2px solid black',
					textAlign: 'center'
				}}
			>
				Points: {state.score ?? 0}
			</div>
			<div
				style={{
					position: 'absolute',
					bottom: '2vh',
					right: '2vh',
					backgroundColor: '#eee',
					borderRadius: '5px',
					fontWeight: 'bold',
					fontSize: '1.3em',
					padding: '1px 6px',
					border: '2px solid black',
					textAlign: 'center'
				}}
			>
				Time Left: {timeLeft}
			</div>
		</Container>
	);
}
