import React, { useState, useEffect, useCallback } from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'src/utils/axios';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const thumbsContainer = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginTop: 16,
	padding: 20
};

const thumb = {
	position: 'relative',
	display: 'inline-flex',
	borderRadius: 2,
	border: '1px solid #eaeaea',
	marginBottom: 8,
	marginRight: 8,
	width: 100,
	height: 100,
	padding: 4,
	boxSizing: 'border-box'
};

const thumbInner = {
	display: 'flex',
	minWidth: 0,
	overflow: 'hidden'
};

const img = {
	display: 'block',
	width: 'auto',
	height: '100%'
};

const getColor = (props) => {
	if (props.isDragAccept) {
		return '#00e676';
	}
	if (props.isDragReject) {
		return '#ff1744';
	}
	if (props.isDragActive) {
		return '#2196f3';
	}
	return '#eeeeee';
};

const DropContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-width: 2px;
	border-radius: 2px;
	border-color: ${(props) => getColor(props)};
	border-style: dashed;
	background-color: #fafafa;
	color: #bdbdbd;
	outline: none;
	transition: border 0.24s ease-in-out;
`;

const Editor = (props) => {
	const { isOpen, toggle, onUpload } = props;
	const [files, setFiles] = useState([]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			let newFiles = acceptedFiles.map((file) => {
				if (file?.type === 'image/png')
					return Object.assign(file, {
						preview: URL.createObjectURL(file)
					});
				return file;
			});
			setFiles([...files, ...newFiles]);
		},
		[files]
	);

	const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept } = useDropzone({
		onDrop
	});

	const thumbs = files.map((file) => (
		<div style={thumb} key={file.name}>
			<div style={thumbInner}>
				{file.type === 'image/png' ? <img src={file.preview} alt="" style={img} /> : <small>{file.name}</small>}
				{/* <button
                  style={{left:10, ...thumbButton}}
                  onClick={() =>{
                      console.log("test")
                  }
                  }
                >
                  X
                </button>
                <button
                  style={thumbButton}
                  onClick={() =>{
                      console.log("test")
                  }
                  }
                >
                  edit
                </button> */}
			</div>
		</div>
	));

	const startUpload = async () => {
		console.log('upload!');
		try {
			var instance = axios.create();
			delete instance.defaults.headers.common['Authorization'];
			var _files = [];
			files.forEach((f) => {
				_files.push({
					name: f['name'],
					type: f['type'],
					size: f['size']
				});
			});

			var { data } = await axios.post(`/s3/signed-url`, {
				files: _files
			});

			console.log('data');
			console.log(data);
			for (let i = 0; i < data.length; i++) {
				try {
					const { url } = data[i];
					await instance.put(url, files[i], {
						headers: {
							'Content-Type': files[i].type
						}
					});
					console.log('instance');
					await onUpload(data[i]);
					files[i].completed = true;
					setFiles([...files]);
				} catch (error) {
					console.error(error);
				}
			}
			setFiles([]);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		},
		[files]
	);
	// console.log(files)

	return (
		<div>
			<Modal isOpen={isOpen} toggle={toggle}>
				<ModalHeader toggle={toggle}>File upload</ModalHeader>
				<ModalBody>
					<div>
						<DropContainer {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
							<input {...getInputProps()} />
							<p>Click to add files or drag 'n' drop them here </p>
						</DropContainer>
						<aside style={thumbsContainer}>{thumbs}</aside>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color="primary"
						onClick={() => {
							startUpload();
						}}
					>
						Save
					</Button>{' '}
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default Editor;
