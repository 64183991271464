import axios from 'src/utils/axios';
import { React, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import LoadingSpinner from 'src/components/spinner';
import AskDate from 'src/components/questions/AskDate';
import { Card, Container, Row, Col, Table, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Spinner } from 'reactstrap';
import moment from 'moment';

import AskText from 'src/components/questions/AskText';

const Event = () => {
	const [data, setData] = useState(null);

	const [loading, setLoading] = useState(false);

	const [beginTime, setBeginTime] = useState(moment().subtract(1, 'M').format('YYYY-MM-01'));
	const [endTime, setEndTime] = useState(moment().subtract(1, 'M').format('YYYY-MM-') + moment().subtract(1, 'M').daysInMonth());

	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(null);

	// const data = {
	// 	"start_date": "2021-01-01",
	// 	"end_date": "2022-01-01",
	// 	"search_total": "1234",
	// 	"global_total": "20000",
	// 	"invoiced_total": "10000",
	// 	"oustanding_total": "10000",
	// 	"results": [
	// 		{
	// 			"instance_created": "1641136194",
	// 			"event_name": "Apple Pie",
	// 			"game_name": "Cheese lickers",
	// 			"instance_id": "i-0507a61e22ebb54fa",
	// 			"billing_rate": "6"
	// 		},
	// 		{
	// 			"instance_created": "1641136194",
	// 			"event_name": "Peer Pie",
	// 			"game_name": "Cheese grater",
	// 			"instance_id": "i-0507a61e22ebb1234fa",
	// 			"billing_rate": "0"
	// 		}
	// 	]
	// }

	const openBilling = (instance) => {
		setOpen(true);
		setSelected(instance);
	};

	const setBilling = async (instance_id, amount) => {
		try {
			await axios.post(`/billing/rate`, { instance_id, billing_rate: amount });
			await getDetails();
		} catch (error) {
			return error;
		}
	};

	const setInvoice = async (amount) => {
		try {
			await axios.post(`/billing/invoice`, { amount });
			await getDetails();
		} catch (error) {
			return error;
		}
	};

	let getDetails = useCallback(async () => {
		try {
			setLoading(true);
			var res = await axios.post(`/billing/list`, { start_date: beginTime, end_date: endTime });
			setData(res.data);
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	}, [beginTime, endTime]);

	useEffect(() => {
		getDetails();
	}, [beginTime, endTime, getDetails]);

	const IntanceEntry = ({ data }) => {
		const { instance_created, event_name, game_name, instance_id, billing_rate } = data;
		return (
			<tr className={billing_rate === 0 ? 'table-warning' : ''}>
				<td>{instance_created}</td>
				<td>{event_name}</td>
				<td>{game_name}</td>
				<td>{instance_id}</td>
				<td
					onClick={() => {
						openBilling(data);
					}}
				>
					&pound;{billing_rate}
				</td>
				<td>
					<i
						className="fa fa-vial"
						onClick={() => {
							setBilling(instance_id, 0);
						}}
					/>
				</td>
			</tr>
		);
	};

	const BillingModal = ({ open, data, toggle }) => {
		const [amount, setAmount] = useState();

		useEffect(() => {
			setAmount(data?.billing_rate);
		}, [data?.billing_rate]);

		return (
			<Modal isOpen={open} toggle={toggle}>
				<ModalHeader toggle={function noRefCheck() {}}>Hourly Rate {data?.instance_id}</ModalHeader>
				<ModalBody>
					<Input
						value={amount}
						type="number"
						onChange={(e) => {
							setAmount(e.target.value);
						}}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={async () => {
							await setBilling(data.instance_id, amount);
							toggle();
						}}
						color="primary"
					>
						OK
					</Button>

					<Button onClick={function noRefCheck() {}}>Cancel</Button>
				</ModalFooter>
			</Modal>
		);
	};

	if (!data) return <LoadingSpinner />;
	return (
		<Container>
			<h1>
				<i className="far fa-calendar-alt"></i> Billing
				{loading && <Spinner />}
				<Link to="/" className="btn btn-outline-orange float-right">
					<i className="fa fa-arrow-left"></i> Back to manage
				</Link>
			</h1>
			<hr />

			<Card>
				<Row>
					<Col md={12} lg={12} style={{ padding: '2rem' }}>
						<Row>
							<Col lg={6}>
								<h2>Total £{data.filter_total}</h2>
								<Button
									color="danger"
									onClick={() => {
										setBeginTime(moment(beginTime).subtract(1, 'M').format('YYYY-MM-01'));
										setEndTime(moment(beginTime).subtract(1, 'M').format('YYYY-MM-') + moment(beginTime).subtract(1, 'M').daysInMonth());
									}}
								>
									Prev Month
								</Button>

								<Button
									color="success"
									onClick={() => {
										setBeginTime(moment(beginTime).add(1, 'M').format('YYYY-MM-01'));
										setEndTime(moment(beginTime).add(1, 'M').format('YYYY-MM-') + moment(beginTime).add(1, 'M').daysInMonth());
									}}
								>
									Next Month
								</Button>
							</Col>
							<Col lg={6}>
								<span className="float-right">
									Invoiced £{data.invoiced_total} / £{data.instances_total} - Outstanding £{data.instances_total - data.invoiced_total}
								</span>

								<AskText type="number" value={'0'} onSubmit={(v) => setInvoice(v)}>
									<Button color="info">Invoice</Button>
								</AskText>
							</Col>
						</Row>
						<div>
							{beginTime}
							<AskDate
								value={beginTime}
								onSubmit={async (v) => {
									await setBeginTime(v);
								}}
							/>
							<br />
							{endTime}
							<AskDate
								value={endTime}
								onSubmit={async (v) => {
									await setEndTime(v);
								}}
							/>
						</div>
						<Table bordered hover style={{ marginTop: '2rem' }}>
							<thead>
								<tr>
									<th>Slave created</th>
									<th>Event name</th>
									<th>Game Name</th>
									<th>Instance ID</th>
									<th>Usage Billing</th>
									<th>#</th>
								</tr>
							</thead>
							<tbody>
								{data.instances.map((entry, index) => {
									return <IntanceEntry key={index} data={entry} />;
								})}
							</tbody>
						</Table>
					</Col>
				</Row>
			</Card>
			<BillingModal
				data={selected}
				open={open}
				toggle={() => {
					setOpen(!open);
				}}
			/>
		</Container>
	);
};

export default Event;
