import React, { useRef } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, UncontrolledTooltip, FormFeedback } from 'reactstrap';
import axios from 'src/utils/axios';
import pAxios from 'axios';

export default function AskFile(props) {
	const { title = 'Upload', description = '', placeholder = '', label = 'New file', type, submitLabel = 'Save', onSubmit = () => {} } = props;
	const [open, setOpen] = React.useState(false);
	const [file, setFile] = React.useState(null);
	const [saving, setSaving] = React.useState(false);
	const [error, setError] = React.useState(false);
	const inputRef = useRef();
	const openDialog = () => {
		setFile(null);
		setError(false);
		setSaving(false);
		setOpen(true);
	};

	const uploadFile = async () => {
		if (!file) return setError('No file selected');
		if (saving) return;
		setSaving(true);
		setError(false);
		try {
			var { url, file_id } = await (
				await axios.post('/s3/signed-url', {
					files: [
						{
							name: file.name,
							size: file.size,
							type: file.type
						}
					]
				})
			).data[0];
			var instance = pAxios.create();
			delete instance.defaults.headers.common['Authorization'];
			await instance.put(url, file, {
				headers: {
					'Content-Type': file.type
				}
			});

			let fileLink = process.env.REACT_APP_DATA_BUCKET + file_id;

			var res = await onSubmit({
				url: fileLink,
				file_id
			});

			console.log('onSubmit done');

			setSaving(false);
			if (!res) return setOpen(false);
			setError(res);
		} catch (error) {
			setError('Failed to upload');
			setSaving(false);
		}
	};

	const openButton = props.children ? (
		<span onClick={openDialog}>{props.children}</span>
	) : (
		<span style={{ cursor: 'pointer' }} onClick={openDialog} id="tooltip-icon">
			<i className="fa fa-cloud-upload-alt" /> Upload
		</span>
	);

	return (
		<>
			{openButton}
			{!props.children && (
				<UncontrolledTooltip placement="right" target="tooltip-icon" trigger="hover">
					Click to upload file
				</UncontrolledTooltip>
			)}
			<Modal fullWidth isOpen={open} toggle={() => setOpen(!open)} aria-labelledby="form-dialog-title">
				<ModalHeader id="form-dialog-title">{title}</ModalHeader>
				<ModalBody>
					{description && <small>{description}</small>}

					<FormGroup>
						{label && <Label for="exampleEmail">{label}</Label>}
						<Input
							ref={inputRef}
							type={type || 'file'}
							name="text"
							onChange={(e) => {
								setFile(e.target.files[0]);
								setError(false);
							}}
							invalid={!!error}
							placeholder={placeholder}
						/>
						{error && <FormFeedback>{error}</FormFeedback>}
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => setOpen(false)} color="secondary">
						Cancel
					</Button>
					<Button onClick={uploadFile} color="primary">
						{saving ? <i className="mx-auto fa fa-cog fa-spin ml-2" /> : submitLabel}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
