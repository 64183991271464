import { React, useEffect, useState, useCallback } from 'react';
import axios from 'src/utils/axios';

import { Link } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Container, Row, Col, Card, Alert, Spinner, Button, Table } from 'reactstrap';

import { useApp } from 'src/appContext';

const Settings = () => {
	const { user } = useApp();

	const [accounts, setAccounts] = useState([]);

	let getDetails = useCallback(async () => {
		try {
			var res = await axios.get(`/staff/all`);
			setAccounts(res.data);
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		getDetails();
	}, [getDetails]);

	return (
		<div className="container">
			<h1>
				<i className="far fa-calendar-alt"></i>
				<Link to="/" className="btn btn-outline-orange float-right">
					<i className="fa fa-arrow-left"></i> Back to home
				</Link>
			</h1>
			<hr />

			<Row>
				<Col lg={6}>
					<Card className="mt-2">
						<Container>
							<span>Accounts</span>
							<Table>
								<tr>
									<td>Id</td>
									<td>Name</td>
									<td>Email</td>
								</tr>
								{accounts.length > 0 ? (
									accounts.map((account, index) => {
										return (
											<tr style={{ textDecoration: user?.staff_id === account.staff_id ? 'underline' : 'none' }}>
												<td>{account.staff_id}</td>
												<td>{account.staff_name}</td>
												<td>{account.staff_email}</td>
											</tr>
										);
									})
								) : (
									<span>No accounts 🤔, how are you logged in?</span>
								)}
							</Table>
						</Container>
					</Card>
				</Col>
				<Col lg={6}>
					<Card className="mt-2">
						<Container>
							<CreateAccountView onReload={getDetails} />
						</Container>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

const CreateAccountView = ({ onReload }) => {
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [password, setPassword] = useState('');
	const [status, setStatus] = useState();

	const CreateAccount = async () => {
		console.log(email, password);

		setStatus('register');
		try {
			var res = await axios.post(`/auth/register`, { name, email, password });
			console.log(res);
			setStatus('success');

			setName('');
			setPassword('');
			setEmail('');

			await onReload();
		} catch (error) {
			setStatus('error');
			console.error(error);
		}
	};

	let buttonActive = name.length > 0 && email.length > 0 && password.length > 0;
	return (
		<>
			<span>Create account</span>
			<hr />
			<Form>
				<FormGroup>
					<Label for="exampleEmail">Name</Label>
					<Input value={name} onChange={(e) => setName(e.target.value)} type="text" />
				</FormGroup>
				<FormGroup>
					<Label for="exampleEmail">Email</Label>
					<Input value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
				</FormGroup>
				<FormGroup>
					<Label for="examplePassword">Password</Label>
					<Input value={password} onChange={(e) => setPassword(e.target.value)} type="password" />
				</FormGroup>

				<Button color={buttonActive ? 'info' : 'secondary'} disabled={!buttonActive} onClick={() => CreateAccount()}>
					{status === 'register' ? <Spinner /> : 'Create'}
				</Button>

				{status === 'error' && <Alert color="danger">Something went wrong</Alert>}
			</Form>
		</>
	);
};

export default Settings;
