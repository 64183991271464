import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { Container, Row, Col, Button, ListGroup, ListGroupItem, Card, Table } from 'reactstrap';
import axios from 'src/utils/axios.js';
import { AskText, AskSelect, AskConfirm, AskFile, AskFiles } from 'src/components/questions/index.js';
import { Link } from 'react-router-dom';
import Spinner from 'src/components/spinner';

export default function Activity() {
	const { id } = useParams();
	const [activity, setActivity] = useState(null);

	const loadActivity = useCallback(async () => {
		try {
			var res = await axios.get(`/activity/${id}`);
			setActivity(res.data);
		} catch (error) {
			alert('Failed to get activity' + error.message);
		}
	}, [id]);

	useEffect(() => {
		loadActivity();
	}, [loadActivity]);

	const createRoom = async (room_name) => {
		try {
			await axios.post('/room/create', { room_name, activity_id: id });
			await loadActivity();
		} catch (error) {
			console.error(error);
			return 'There was a server error.';
		}
	};

	const updateActivity = async (key, value) => {
		try {
			activity[key] = value;
			await axios.post(`/activity/${id}/update`, { ...activity });
			await loadActivity();
		} catch (error) {
			console.error(error);
			return 'There was a server error.';
		}
	};

	const setBackground = async (background) => {
		try {
			await axios.post(`/activity/${id}/background`, { background });
			await loadActivity();
		} catch (error) {
			console.error(error);
			return 'There was a server error.';
		}
	};

	
	const setSlideshowBackground = async (background) => {
		try {
			await axios.post(`/activity/${id}/slideshow/background`, { background });
			await loadActivity();
		} catch (error) {
			console.error(error);
			return 'There was a server error.';
		}
	};

	const deleteImage = async (id) => {
		try {
			await axios.post(`/activity/${id}/delete-image`, { image_id: id });
			await loadActivity();
		} catch (error) {
			console.error(error);
			return 'There was a server error.';
		}
	};

	const updateInstruction = async (id, type, value) => {
		try {
			var _instruction = activity.instructions.find((i) => i.instruction_id === id);
			_instruction[type] = value;
			console.log(_instruction);
			await axios.post(`/instruction/update/${id}`, _instruction);
			await loadActivity();
		} catch (error) {
			console.error(error);
			return 'There was a server error.';
		}
	};

	const createInstruction = async (title) => {
		try {
			await axios.post('/instruction/create', { title, activity_id: id });
			await loadActivity();
		} catch (error) {
			console.error(error);
			return 'There was a server error.';
		}
	};

	if (!activity) return <Spinner />;
	const { rooms, instructions, map_pieces } = activity;

	return (
		<Container>
			<h1>
				Activity - {activity.activity_name}
				<Link to="/">
					<Button color="secondary" outline className="float-right mt-2">
						<i className="fa fa-arrow-left" aria-hidden="true"></i> Return
					</Button>
				</Link>
			</h1>
			<hr />
			<Row>
				<Col md={6}>
					<Card style={{ padding: '20px' }}>
						Activity Name
						<h5>
							{activity.activity_name} &nbsp;
							<AskText value={activity.activity_name} onSubmit={(v) => updateActivity('activity_name', v)} />
						</h5>
						Welcome (QR Code) Message
						<h5>
							{activity.welcome_text} &nbsp;
							<AskText value={activity.welcome_text} onSubmit={(v) => updateActivity('welcome_text', v)} />
						</h5>
						Default Room:
						<h5>
							{rooms.find((r) => activity.default_room === r.room_id)?.room_name}
							<AskSelect
								value={activity.default_room}
								values={rooms.map((r) => {
									return { value: r.room_id, text: r.room_name };
								})}
								onSubmit={async (v) => await updateActivity('default_room', v)}
								title="Create Task"
								label="Task Type"
							></AskSelect>
						</h5>
						Show Map:
						<h5>
							{activity.show_map_icon ? 'Yes' : 'No'}
							<AskSelect
								value={activity.show_map_icon}
								values={[
									{ value: 1, text: 'Yes' },
									{ value: 0, text: 'No' }
								]}
								onSubmit={async (v) => await updateActivity('show_map_icon', v)}
								title="Show Map"
								label="Show map icon"
							></AskSelect>
						</h5>
						{activity.show_map_icon && (
							<div>
								Map Label:
								<h5>
									{activity.map_label}
									<AskText value={activity.map_label} onSubmit={(v) => updateActivity('map_label', v)} />
								</h5>
								Map Title:
								<h5>
									{activity.map_title}
									<AskText value={activity.map_title} onSubmit={(v) => updateActivity('map_title', v)} />
								</h5>
								Map Description:
								<h5>
									{activity.map_description}
									<AskText value={activity.map_description} onSubmit={(v) => updateActivity('map_description', v)} />
								</h5>
							</div>
						)}
						<br />
						Activity Presenter Background
						<h5>
							<AskFile onSubmit={({ url, file_id }) => setBackground(file_id)} />
						</h5>
						{activity.presenter_background_url && (
							<>
								<img style={{ maxWidth: '300px', maxHeight: '600px' }} src={activity.presenter_background_url} alt="background"></img>
								<AskConfirm onSubmit={() => setBackground(null)}>
									<Button>Remove Image</Button>
								</AskConfirm>
							</>
						)}
						<div className="mt-4">
							<h4>
								Instructions
								<AskText onSubmit={(v) => createInstruction(v)} title="Title" label="Instruction Title">
									<Button color="primary" outline className="ml-3">
										<i className="fa fa-plus" aria-hidden="true"></i> Create Instruction
									</Button>
								</AskText>
							</h4>
							<Table bordered hover>
								<thead>
									<tr>
										<th>Title</th>
										<th>Description</th>
										<th>Image</th>
									</tr>
								</thead>
								<tbody>
									{instructions.map((i, key) => {
										return (
											<tr key={key}>
												<td>
													{i.title}
													<AskText
														value={i.title}
														onSubmit={(v) => updateInstruction(i.instruction_id, 'title', v)}
														title="Title"
														label="Change Title"
													/>
												</td>
												<td>
													<div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' }}>{i.description}</div>
													<AskText
														value={i.description}
														onSubmit={(v) => updateInstruction(i.instruction_id, 'description', v)}
														title="Description"
														label="Change Description"
													/>
												</td>
												<td>
													<AskFile onSubmit={({ url, file_id }) => updateInstruction(i.instruction_id, 'image', file_id)} />
													<br />
													<img alt="" src={i.image_url} style={{ maxWidth: '100px', maxHeight: '100px' }} />
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</div>
						<div className="mt-4">
							<h4>
								Map Images
								<AskFiles
									onDone={() => {
										loadActivity();
									}}
									onUpload={async (data) => {
										try {
											await axios.post(`/activity/${id}/uploadMapPiece`, data);
										} catch (error) {
											console.log(error);
										}
									}}
								>
									<Button color="primary" outline className="ml-3">
										<i className="fa fa-plus" aria-hidden="true"></i> Upload map pieces
									</Button>
								</AskFiles>
							</h4>
							<Table bordered hover>
								<thead>
									<tr>
										<th>Piece Number</th>
										<th>Preview</th>
									</tr>
								</thead>
								<tbody>
									{map_pieces.map((p, key) => {
										return (
											<tr key={key}>
												<td>{p.piece_number}</td>
												<td>
													<img style={{ maxWidth: '60px' }} src={p.image_url} alt={''} />
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</div>
					</Card>
				</Col>
				<Col md={6}>
					<h3>
						Rooms
						<AskText value="" onSubmit={createRoom} title="Create Room" label="Room Name">
							<Button color="primary" outline className="ml-3">
								<i className="fa fa-plus" aria-hidden="true"></i> Create Room
							</Button>
						</AskText>
					</h3>
					<ListGroup className="mt-2">
						{rooms.map((r, key) => {
							return (
								<ListGroupItem key={key}>
									<Link to={`/room/${r.room_id}`}>{r.room_name}</Link>
								</ListGroupItem>
							);
						})}
					</ListGroup>
					&nbsp;
					<h3>
						Images
						{/* <AskText
							value=""
							// onSubmit={createRoom}
							// title="Create Room"
							// label="Room Name"
						>
							<Button color="primary" outline className="ml-3">
								<i className="fa fa-plus" aria-hidden="true"></i> Upload Image
							</Button>
						</AskText> */}
						<AskFiles
							onDone={() => {
								loadActivity();
							}}
							onUpload={async (data) => {
								try {
									console.log(data);
									// if (data.type === "image/png")
									await axios.post(`/activity/${id}/upload-image`, data);
								} catch (error) {
									console.log(error);
								}
							}}
						>
							<Button color="primary" outline className="ml-3">
								<i className="fa fa-plus" aria-hidden="true"></i> Upload Image
							</Button>
						</AskFiles>
					</h3>
					<ListGroup className="mt-2">
						{activity.images.map((i, index) => {
							return (
								<ListGroupItem key={index}>
									<div>
										{i.title}

										<AskConfirm description="Delete this image?" onSubmit={(res) => deleteImage(i.image_id)}>
											<i style={{ color: 'red', cursor: 'pointer' }} className="fa fa-times fa-md float-right" />
										</AskConfirm>
									</div>
									<img style={{ maxWidth: '60px' }} src={i.image_url} alt={i.image_title} />
								</ListGroupItem>
							);
						})}
					</ListGroup>
				</Col>
			</Row>
			<Row>

				<Col md={6}>
					<Card style={{ padding: '20px' }}>
						<h3>
							Slideshow
						</h3>
						<br />
						Default Slideshow Background
						<h5>
							<AskFile onSubmit={({ url, file_id }) => setSlideshowBackground(file_id)} />
						</h5>
						{activity.slideshow_background_url && (
							<>
								<img style={{ maxWidth: '300px', maxHeight: '600px' }} src={activity.slideshow_background_url} alt="background"></img>
								<AskConfirm onSubmit={() => setSlideshowBackground(null)}>
									<Button>Remove Image</Button>
								</AskConfirm>
							</>
						)}

					</Card>
				</Col>
			</Row>
		</Container>
	);
}
