import React from 'react';
import { Spinner as Spin } from 'reactstrap';

export default function Spinner({ message ="Loading", fullscreen }) {

	let thisStyle = {
		textAlign: 'center',
		// position: "absolute",
		// left: "50%",
		// top: "50%",
		transform: 'translateY(200%)'
	}


	if (fullscreen)
		thisStyle = {
			textAlign: 'center',
			position: "absolute",
			left: "50%",
			top: "50%",
		}


	return (
		<div style={thisStyle}>
			<Spin size="small" color="dark" />
			<br />
			{message}
			<br />
		</div>
	);
}
