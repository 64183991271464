import axios from 'src/utils/axios';
import { React, useEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import Spinner from 'src/components/spinner';
import { AskConfirm, AskFile, AskText, AskSelect } from 'src/components/questions';
import {
	Card,
	Container,
	Row,
	Col,
	Button,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
	ButtonDropdown,
	ButtonGroup,
	UncontrolledButtonDropdown
} from 'reactstrap';
// import socket from 'src/utils/socket';
import AskDatetime from 'src/components/questions/AskDatetime';

const Event = () => {
	const { id: event_id } = useParams();
	const [event, setEvent] = useState(null);
	const [downloading, setDownloading] = useState(false);

	let getDetails = useCallback(async () => {
		try {
			var res = await axios.get(`/event/${event_id}`);
			setEvent(res.data);
		} catch (error) {
			console.error(error);
		}
	}, [event_id]);

	useEffect(() => {
		getDetails();
		let interval = setInterval(getDetails, 10000);
		return () => {
			clearInterval(interval);
		};
	}, [getDetails]);

	const downloadImages = async () => {
		if (downloading) return;
		try {
			setDownloading(true);
			const result = await axios.post(`/s3/${event_id}/event-images`);
			console.log(result);
			await getDetails();
			setDownloading(false);
		} catch (error) {
			setDownloading(false);
			return error;
		}
	};

	const deleteImages = async () => {
		if (downloading) return;
		try {
			setDownloading(true);
			const result = await axios.post(`/s3/${event_id}/event-images/delete`);
			console.log(result);
			await getDetails();
			setDownloading(false);
		} catch (error) {
			setDownloading(false);
			return error;
		}
	};

	const createGame = async (game_count) => {
		try {
			await axios.post('/game/create', { game_count, event_id });
			await getDetails();
		} catch (error) {
			return error;
		}
	};

	const renameGame = async (game_id, name) => {
		try {
			await axios.post(`/game/${game_id}/update`, { name });
			await getDetails();
		} catch (error) {
			return error;
		}
	};

	const launchInstance = async (game_id) => {
		try {
			await axios.get(`/s3/launch-instance/${game_id}`);
			await getDetails();
		} catch (error) {
			return error;
		}
	};

	const launchAll = () => {
		let t = 1;
		for (let i = 0; i < event.games.length; i++) {
			const g = event.games[i];
			if (!g.instance) setTimeout(() => launchInstance(g.game_id), (t += 3000));
		}
	};

	const setSlideshowBranding = async (image, size) => {
		try {
			if (size === 'large') await axios.post(`/event/${event_id}/slideshow/brand-large`, { image });
			if (size === 'small') await axios.post(`/event/${event_id}/slideshow/brand-small`, { image });
			await getDetails();
		} catch (error) {
			console.error(error);
			return 'There was a server error.';
		}
	};

	const setEventDetail = async (key, value) => {
		try {
			let newEvent = { ...event };
			newEvent[key] = value;
			await axios.post(`/event/${event_id}/update`, newEvent);
			await getDetails();
		} catch (error) {
			return error;
		}
	};

	const joinMeeting = (instanceID, bot_name) => {
		axios.post(`/s3/instance-command/${instanceID}`, {
			instanceID,
			command: 'join_' + event.meeting_platform.toLowerCase(),
			meeting_url: event.meeting_url,
			bot_name
		});
		// socket.send('bot_command', {
		// 	instanceID,
		// 	command: 'join_' + event.meeting_platform.toLowerCase(),
		// 	meeting_url: event.meeting_url,
		// 	bot_name
		// });
	};

	const allJoinMeeting = () => {
		for (let i = 0; i < event.games.length; i++) {
			const g = event.games[i];
			if (g.instance) joinMeeting(g.instance.instance_id, g.game_name);
		}
	};

	const killProcess = (instanceID, processName) => {
		axios.post(`/s3/instance-command/${instanceID}`, {
			instanceID,
			command: 'kill',
			processName
		});
		// socket.send('bot_command', {
		// 	instanceID,
		// 	command: 'kill',
		// 	processName
		// });
	};

	const terminate = async (instanceID) => {
		axios.post(`/s3/instance-command/${instanceID}`, {
			instanceID,
			command: 'terminate'
		});
		// socket.send('bot_command', {
		// 	instanceID,
		// 	command: 'terminate'
		// });
		await getDetails();
	};

	const allTerminate = () => {
		for (let i = 0; i < event.games.length; i++) {
			const g = event.games[i];
			if (g.instance) terminate(g.instance.instance_id);
		}
	};

	const showGame = (instanceID, game_id) => {
		axios.post(`/s3/instance-command/${instanceID}`, {
			instanceID,
			platform: event.meeting_platform.toLowerCase(),
			command: 'share',
			gameURL: `https://myactivityhub.net/present/${game_id}`
		});
		// socket.send('bot_command', {
		// 	instanceID,
		// 	platform: event.meeting_platform.toLowerCase(),
		// 	command: 'share',
		// 	gameURL: `https://myactivityhub.net/present/${game_id}`
		// });
	};

	const allShowGame = () => {
		for (let i = 0; i < event.games.length; i++) {
			const g = event.games[i];
			if (g.instance) showGame(g.instance.instance_id, g.game_id);
		}
	};

	const GameEntry = ({ game }) => {
		const resetGame = async (gameId) => {
			console.log('reset ' + gameId);
			try {
				await axios.get(`https://api.myactivityhub.net/game/${gameId}/reset`);
			} catch (error) {
				console.error(error);
			}
		};
		return (
			<tr>
				<td className="td-team-name" data-game="$gid">
					{' '}
					{game.game_name} &nbsp;
					<AskText value={game.game_name} onSubmit={async (v) => await renameGame(game.game_id, v)} />
				</td>
				<td className="td-exp-players" data-expected={game.expected_players} data-game="$gid">
					-
				</td>
				<td className="td-points" data-game="$gid">
					{game.score ?? 0}
				</td>
				<td> - </td>
				<td data-game="$gid" className="td-jam-name text-center">
					{game.instance ? (
						<>
							{game.instance.instance_id}
							<br />
							{game.instance.instance_ip || 'Booting up'}&nbsp;
                            <a href={`${process.env.REACT_APP_BACKEND_URL}/game/${game.game_id}/vnc`}>🔌</a>
							<br />
							{game.instance.region}
						</>
					) : (
						<>-None-</>
					)}
				</td>
				<td>
					<UncontrolledDropdown>
						<DropdownToggle caret>
							<i className="fas fa-robot mr-1"></i> Bot
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem header>Bot Controls</DropdownItem>
							{game.instance ? (
								<>
									<DropdownItem onClick={() => joinMeeting(game.instance.instance_id, game.game_name)}>
										<i className="fa-fw far fa-play-circle"></i> Join Meeting
									</DropdownItem>
									<DropdownItem onClick={() => killProcess(game.instance.instance_id, event.meeting_platform.toLowerCase())}>
										<i className="fa-fw fas fa-skull-crossbones"></i> Kill Meeting
									</DropdownItem>
									<DropdownItem divider />
									<DropdownItem onClick={() => showGame(game.instance.instance_id, game.game_id)}>
										<i className="fa-fw far fa-play-circle"></i> Open &amp; Share Game
									</DropdownItem>
									<DropdownItem onClick={() => killProcess(game.instance.instance_id, 'chrome')}>
										<i className="fa-fw fas fa-skull-crossbones"></i> Kill Game Presenter
									</DropdownItem>
									<DropdownItem divider />
									<DropdownItem onClick={() => terminate(game.instance.instance_id)}>
										<i className="fa-fw fas fa-skull-crossbones"></i> Terminate Bot
									</DropdownItem>
								</>
							) : (
								<DropdownItem onClick={() => launchInstance(game.game_id)}>
									<i className="fa-fw far fa-play-circle"></i> START
								</DropdownItem>
							)}
						</DropdownMenu>
					</UncontrolledDropdown>

					<span className="dropdown">
						<button className="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" id="actionDropdown$gid" data-toggle="dropdown">
							<i className="fas fa-gamepad mr-1"></i> Game
						</button>
						<div className="dropdown-menu dropdown-menu-right">
							<a href={`/game/${game.game_id}/progress`} className="dropdown-item">
								<i className="fa-fw fa fa-tasks"></i> View Team Progress
							</a>
							<div className="dropdown-divider"></div>
							<a href={`/present/${game.game_id}`} className="dropdown-item">
								<i className="fa-fw far fa-play-circle"></i> Open Presenter Screen
							</a>
							<a href="/play/$g[game_key]" className="dropdown-item disabled">
								<i className="fa-fw far fa-play-circle"></i> Open Play Screen
							</a>
							<div className="dropdown-divider"></div>
							<button
								data-game="$gid"
								className="dropdown-item resetGame"
								onClick={() => {
									resetGame(game.game_id);
								}}
							>
								<i className="fa-fw fas fa-skull-crossbones"></i> Reset Game
							</button>
						</div>
					</span>
				</td>
			</tr>
		);
	};

	if (!event) return <Spinner />;
	return (
		<Container>
			<h1>
				<i className="far fa-calendar-alt"></i> {event.event_name} - {event.activity_name}
				<Link to="/" className="btn btn-outline-orange float-right">
					<i className="fa fa-arrow-left"></i> Back to manage
				</Link>
			</h1>
			<hr />

			<Card>
				<Row>
					<Col md={12} lg={6}>
						<div style={{ maxWidth: '500px' }} className="mt-2">
							<table className="table table-bordered">
								<tbody>
									<tr>
										<th>Event Name</th>
										<td>
											{event.event_name} <AskText value={event.event_name} onSubmit={async (v) => await setEventDetail('event_name', v)} />
										</td>
									</tr>
									<tr>
										<th>Meeting Platform</th>
										<td>
											{event.meeting_platform}
											<AskSelect
												value={event.meeting_platform}
												values={[
													{ value: 'ZOOM', text: 'Zoom' },
													{ value: 'TEAMS', text: 'Teams' }
												]}
												onSubmit={async (v) => {
													await setEventDetail('meeting_platform', v);
												}}
												title="Platform"
												label="Meeting Platform"
											></AskSelect>
										</td>
									</tr>
									<tr>
										<th>Meeting URL</th>
										<td style={{ maxWidth: '40px', overflow: 'hidden' }}>
											{event.meeting_url} <AskText value={event.meeting_url} onSubmit={async (v) => await setEventDetail('meeting_url', v)} />
										</td>
									</tr>
									<tr>
										<th>Event Start</th>
										<td>
											{new Date(event.event_start * 1000).toLocaleString().slice(0, -3)}
											<AskDatetime
												value={new Date(event.event_start * 1000).toISOString().slice(0, -8)}
												onSubmit={async (v) => {
													await setEventDetail('event_start', new Date(v).getTime() / 1000);
												}}
											/>
										</td>
									</tr>
									<tr>
										<th>Event Ends</th>
										<td>
											{new Date(event.event_end * 1000).toLocaleString().slice(0, -3)}
											<AskDatetime
												value={new Date(event.event_end * 1000).toISOString().slice(0, -8)}
												onSubmit={async (v) => {
													await setEventDetail('event_end', new Date(v).getTime() / 1000);
												}}
											/>
										</td>
									</tr>
									<tr>
										<th>Slideshow Branding (Large)</th>
										<td>
											<h5>
												<AskFile onSubmit={({ url, file_id }) => setSlideshowBranding(file_id, 'large')} />
											</h5>
											{event.slideshow_brand_large && (
												<>
													<img style={{ maxWidth: '100px', maxHeight: '600px' }} src={event.slideshow_brand_large} alt="Large Branding"></img>
													<AskConfirm onSubmit={() => setSlideshowBranding(null, 'large')}>
														<Button>Remove Image</Button>
													</AskConfirm>
												</>
											)}
										</td>
									</tr>
									<tr>
										<th>Slideshow Branding (Small)</th>
										<td>
											<h5>
												<AskFile onSubmit={({ url, file_id }) => setSlideshowBranding(file_id, 'small')} />
											</h5>
											{event.slideshow_brand_small && (
												<>
													<img style={{ maxWidth: '100px', maxHeight: '600px' }} src={event.slideshow_brand_small} alt="Small Branding"></img>
													<AskConfirm onSubmit={() => setSlideshowBranding(null, 'small')}>
														<Button>Remove Image</Button>
													</AskConfirm>
												</>
											)}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Col>

					<Col md={12} lg={6}>
						<h2>Resources</h2>-<h2>Automation</h2>
						<p>
							<small> These are disabled for now </small>
						</p>
						<AskConfirm
							description="Start All Bots?"
							onSubmit={async (res) => {
								await launchAll();
							}}
						>
							<button className="autoBTN btn btn-sm btn-outline-secondary mb-1">
								<i className="far fa-play-circle"></i> Start All Bots
							</button>
						</AskConfirm>
						<br />
						<AskConfirm
							description="All Join Meeting?"
							onSubmit={async (res) => {
								await allJoinMeeting();
							}}
						>
							<button className="autoBTN btn btn-sm btn-outline-secondary mb-1">
								<i className="fa fa-sign-in-alt"></i> All Join Meeting
							</button>
						</AskConfirm>
						<br />
						<AskConfirm
							description="All Show Game?"
							onSubmit={async (res) => {
								await allShowGame();
							}}
						>
							<button className="autoBTN btn btn-sm btn-outline-secondary mb-1">
								<i className="fa fa-fw far fa-play-circle"></i> All Show Game
							</button>
						</AskConfirm>
						<br />
						<AskConfirm
							description="Terminate All Bots?"
							onSubmit={async (res) => {
								await allTerminate();
							}}
						>
							<button className="autoBTN btn btn-sm btn-outline-danger mb-1">
								<i className="far fa-stop-circle"></i> Terminate All Bots
							</button>
						</AskConfirm>
						<br />
						<button
							className="autoBTN btn btn-sm btn-outline-secondary mb-1 disabled"
							hidden
							data-href="/manage/event_script.php?fiveMinsEvent=<?=$event['event_id']?>"
						>
							<i className="fa fa-clock"></i> Last Five Minutes
						</button>
						<br />
					</Col>

					<Col lg={12} id="gameDiv">
						<div id="gameInDiv">
							<AskText
								title="Create Games"
								label="How many games would you like to create ?"
								type="number"
								onSubmit={async (v) => await createGame(Number(v))}
							>
								<button id="btnGameCreate" className="float-right btn btn-outline-primary mb-1">
									<i className="fa fa-plus" aria-hidden="true"></i> Create Game
								</button>
							</AskText>
							{event?.games[0] && (
								<Link to={`/game/${event.games[0].game_id}/progress`}>
									<Button color="primary" outline className="float-right mx-1">
										<i className="fa fa-tasks" />
										&nbsp;View Progress
									</Button>
								</Link>
							)}
							<Link to={`/event/${event_id}/scoring`}>
								<Button color="primary" outline className="float-right mx-1">
									<i className="fa fa-images" />
									&nbsp;Score Images
								</Button>
							</Link>
							<Link to={`/slideshow/${event_id}`} target="_blank">
								<Button color="primary" outline className="float-right mx-1">
									<i className="fa fa-images" />
									&nbsp;Slideshow
								</Button>
							</Link>

							<ButtonGroup>
								<Button
									outline
									color={event.slideshow_zip == null ? 'danger' : event.slideshow_zip_progress === 'DONE' ? 'success' : 'warning'}
									onClick={() => {
										if (event.slideshow_zip == null && event.slideshow_zip_progress == null) downloadImages();
										if (event.slideshow_zip != null && event.slideshow_zip_progress === 'DONE')
											//download the zip
											window.open(event.slideshow_zip_url, '_blank').focus();
									}}
									disabled={(event.slideshow_zip != null && event.slideshow_zip_progress !== 'DONE') || downloading}
								>
									{downloading ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-images" />}
									&nbsp;
									{event.slideshow_zip == null
										? 'Start Images download'
										: event.slideshow_zip_progress === 'DONE'
										? 'Download ready'
										: event.slideshow_zip_progress}
								</Button>
								<UncontrolledButtonDropdown>
									<DropdownToggle
										color={event.slideshow_zip == null ? 'danger' : event.slideshow_zip_progress === 'DONE' ? 'success' : 'warning'}
										outline
										caret
									/>
									<DropdownMenu right>
										<DropdownItem header>Actions</DropdownItem>
										<DropdownItem
											onClick={() => {
												deleteImages();
											}}
										>
											<i className="fa fa-fw fa-trash mr-2"></i>
											Delete
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledButtonDropdown>
							</ButtonGroup>

							<table id="gameTable" style={{ backgroundColor: 'white' }} className="table table-striped">
								<thead>
									<tr>
										<th>Name</th>
										<th>Players</th>
										<th>Points</th>
										<th>State</th>
										<th className="text-center">Bot</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{event.games
										.sort((a, b) => {
											let na = parseInt(a.score ?? 0);
											let nb = parseInt(b.score ?? 0);

											if (na < nb) return 1;
											if (na > nb) return -1;
											return 0;
										})
										.map((ga) => {
											return <GameEntry game={ga} key={ga.game_id} />;
										})}
								</tbody>
							</table>
						</div>
					</Col>

					<Col lg={12}>
						<ul id="photoList" className="list-group"></ul>
					</Col>
				</Row>
			</Card>
		</Container>
	);
};

export default Event;
