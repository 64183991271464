import axios from './axios';

const graphql = async (query, postURL = '') => {
	const { data } = await axios.post(postURL + '/graphql', {
		...query
	});

	if (data.errors) {
		throw new Error(data.errors[0].message);
	}

	return data.data;
};

export default graphql;
