import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { Container, Row, Col, Button, Input, Spinner as HeartSpinner, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'src/utils/axios';
import Spinner from 'src/components/spinner';
import moment from 'moment';

export default function EventScoring() {
	const [score, setScore] = useState(1);
	const [images, setImages] = useState(null);
	const [focusedImage, setFocusedImage] = useState(null);
	const [saving, setSaving] = useState(false);
	const [heartSpinner, setHeartSpinner] = useState(false);

	const { id: event_id } = useParams();
	const getImages = useCallback(async () => {
		try {
			var res = await axios.get(`/scoring/${event_id}`);
			setImages(res.data);
			console.log(res.data);
			if (!focusedImage) {
				setFocusedImage(res.data[0]);
			}
		} catch (error) {
			alert('Failed to load images');
		}
	}, [setFocusedImage, setImages, focusedImage, event_id]);

	const scoreImage = async () => {
		try {
			setSaving(true);
			await axios.post('/scoring/score', {
				score,
				gte_id: focusedImage.gte_id
			});
			setFocusedImage(null); //clear image so a new default will get selected
			await getImages();
			setSaving(false);
		} catch (error) {
			alert('Failed to score image');
		}
	};

	const markForPresent = async ({ gte_id }) => {
		try {
			setHeartSpinner(true);
			await axios.get(`/scoring/markPresent/${gte_id}`);
			await getImages();
			setHeartSpinner(false);
			setFocusedImage({ ...focusedImage, presentation_worthy: !focusedImage.presentation_worthy });
		} catch (error) {
			setHeartSpinner(false);
			return 'Failed to mark for present';
		}
	};

	useEffect(() => {
		var int = setInterval(getImages, 5000);
		return () => {
			clearInterval(int);
		};
	}, [getImages]);

	return (
		<Container>
			<h1>
				Scoring {focusedImage?.game_name}
				<Link to={`/event/${event_id}`}>
					<Button color="secondary" outline className="float-right mt-2">
						<i className="fa fa-arrow-left" aria-hidden="true"></i> Return
					</Button>
				</Link>
			</h1>
			<hr />
			{!focusedImage ? (
				images && images.length === 0 ? (
					<div>No images to be scored, come back later or wait on this page.</div>
				) : (
					<Spinner text="Checking for images" />
				)
			) : (
				<>
					<Row>
						<Col md={8} style={{ textAlign: 'center' }}>
							<img alt="" style={{ maxWidth: '600px', height: '450px' }} src={focusedImage.entry_image} />
							<br />
							Posted {moment.unix(focusedImage.submitted_timestamp).fromNow()}
						</Col>
						<Col md={4}>
							{heartSpinner ? (
								<HeartSpinner className="float-right" />
							) : (
								<i
									onClick={() => markForPresent(focusedImage)}
									className={'fa-heart float-right ' + (focusedImage.presentation_worthy ? 'fas' : 'far')}
									style={{ fontSize: '3em', color: focusedImage.presentation_worthy ? '#e83e8c' : '' }}
								/>
							)}
							<h3>{focusedImage.task_title}</h3>
							{focusedImage.task_description}
							<hr />
							<Input
								disabled={saving}
								min={1}
								max={focusedImage.reward}
								id="exampleRange"
								name="range"
								type="range"
								value={score}
								onChange={(e) => setScore(e.target.value)}
							/>
							<Button disabled={saving} onClick={scoreImage} className="mt-3" color="primary">
								Score {score} points to {focusedImage.game_name} {saving && <i className="fas fa-sync fa-spin" />}
							</Button>
						</Col>
					</Row>
					<div
						style={{
							border: '2px solid black',
							minHeight: '100px',
							marginTop: '30px',
							overflowY: 'hidden',
							overflowX: 'scroll',
							whiteSpace: 'nowrap'
						}}
					>
						{images.map((img) => {
							var selected = img.gte_id === focusedImage.gte_id;
							return (
								<div style={{ display: 'inline-block', textAlign: 'center', verticalAlign: 'top' }} className="my-2 mx-2">
									<img
										alt=""
										style={{ maxHeight: '160px', cursor: 'pointer', border: selected ? '3px solid blue' : '' }}
										onClick={() => {
											setFocusedImage(img);
											setScore(1);
										}}
										src={img.entry_image}
									/>
									<br />
									<Badge color="primary">{img.game_name}</Badge>
								</div>
							);
						})}
					</div>
				</>
			)}
		</Container>
	);
}
