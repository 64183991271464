import axios from 'src/utils/axios';
import React, { useCallback, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Table, Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Spinner from 'src/components/spinner';
import moment from 'moment';
import { AskText, AskConfirm, AskSelect } from 'src/components/questions';
import ImageModal from './ImageModal';

export default function EventProgress() {
	const { id: game_id } = useParams();
	const [event, setEvent] = useState(null);

	const reload = useCallback(async () => {
		try {
			var res = await axios.get(`/game-progress/${game_id}`);
			setEvent(res.data);
		} catch (error) {
			return error;
		}
	}, [game_id]);

	useEffect(() => {
		reload();
	}, [reload]);

	useEffect(() => {
		var interval = setInterval(reload, 10000);
		return () => clearInterval(interval);
	}, [reload]);

	const updateGTA = async (gte_id, key, value) => {
		try {
			var gta = event.entries.find((entry) => entry.gte_id === gte_id);
			gta[key] = value;
			await axios.post(`/game/update/task-entry/${gte_id}`, gta);
			await reload();
		} catch (error) {
			return error;
		}
	};

	const history = useHistory();
	const switchGame = (game_id) => {
		history.push(`/game/${game_id}/progress`);
		setEvent(null);
	};

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	const nextGame = () => {
		for (let i = 0; i < event.allGames.length - 1; i++) {
			const g = event.allGames[i];
			if (g.game_id === game_id) return switchGame(event.allGames[i + 1].game_id);
		}
		switchGame(event.allGames[0].game_id);
	};

	if (!event) return <Spinner />;
	return (
		<Container>
			<h1>
				Event Progress | {event.game.game_name}
				<Link to={`/event/${event.game.event_id}`} className="btn btn-outline-orange float-right">
					<i className="fa fa-arrow-left"></i> Back to event
				</Link>
			</h1>
			<hr />
			<Dropdown className="float-right" isOpen={dropdownOpen} toggle={toggle}>
				<DropdownToggle caret>Switch Game ({event.allGames.length + ' Total'})</DropdownToggle>
				<DropdownMenu>
					{event.allGames.map((g) => {
						var selected = g.game_id === game_id ? '(Selected)' : '';
						return (
							<DropdownItem onClick={() => switchGame(g.game_id)}>
								{g.game_id} - {g.game_name} {selected}
							</DropdownItem>
						);
					})}
				</DropdownMenu>
				<Button onClick={() => nextGame()} className="ml-1" style={{ display: 'inline' }}>
					Next
				</Button>
			</Dropdown>
			<h4>
				Total Score:&nbsp;
				{event.game.total_score}
			</h4>

			<Table bordered striped>
				<thead>
					<tr>
						<th>Task Name</th>
						<th>Submitted Time</th>
						<th>Answers</th>
						<th>Their Answer</th>
						<th>Score</th>
						<th>Slideshow</th>
					</tr>
				</thead>
				<tbody>
					{event.entries.map((e) => {
						return (
							<tr>
								<th>{e.task_title}</th>
								<td>{moment.unix(e.submitted_timestamp).fromNow()}</td>
								<td>
									<small>
										{e.answers &&
											e.answers.map(({ answer_value }, index) => {
												console.log(e.answers.length, index);
												if (e.answers.length === index + 1) return answer_value;
												return answer_value + ' | ';
											})}
									</small>
								</td>
								<td>
									{e.entry_image ? (
										<ImageModal src={e.entry_image}>
											<img style={{ maxWidth: '100px', maxHeight: '100px' }} src={e.entry_image} alt="" />
										</ImageModal>
									) : (
										<>
											{e.task_answer} &nbsp;
											<AskText value={e.task_answer} onSubmit={async (v) => await updateGTA(e.gte_id, 'task_answer', v)} />
										</>
									)}
								</td>
								<td>
									{e.reward_given} &nbsp;
									<AskText value={e.reward_given} type="number" step="1" onSubmit={async (v) => await updateGTA(e.gte_id, 'reward_given', v)} />
								</td>
								<td>
									{e.presentation_worthy ? 'YES' : 'NO'} &nbsp;
									<AskSelect
										value={e.presentation_worthy}
										values={[
											{ value: false, text: 'NO' },
											{ value: true, text: 'YES' }
										]}
										onSubmit={async (v) => await updateGTA(e.gte_id, 'presentation_worthy', v)}
										title="Presentation Worthy?"
										label="Yes / No? (1/0)"
									></AskSelect>
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>

			<ClueTable clues={event.clues} reload={reload} />
		</Container>
	);
}

const ClueTable = ({ clues, reload }) => {
	const { id: game_id } = useParams();

	const giveClue = async (clue_id) => {
		try {
			await axios.post(`/game-progress/${game_id}/give-clue`, {
				clue_id
			});
			await reload();
		} catch (error) {
			console.error(error);
			return 'There was a server error';
		}
	};

	return (
		<Table className="mt-5" bordered striped>
			<thead>
				<tr>
					<th>Clue</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{clues.map((c) => {
					return (
						<tr>
							<td>{c.value}</td>
							<td>
								{c.game_clue_id ? (
									'Given'
								) : (
									<AskConfirm description="Give clue?" onSubmit={() => giveClue(c.clue_id)}>
										<Button size="sm">Give Clue</Button>
									</AskConfirm>
								)}
							</td>
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
};
