import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function ImageModal(props) {
	const { src } = props;

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	return (
		<div>
			{props.children && (
				<span style={{ cursor: 'pointer' }} onClick={toggle}>
					{props.children}
				</span>
			)}
			<Modal size="lg" isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Image</ModalHeader>
				<ModalBody style={{ textAlign: 'center' }}>
					<img src={src} style={{ maxWidth: '600px', maxHeight: '600px' }} alt="" />
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}
