import { useCallback, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import { Container, Row, Col, Button, ListGroup, ListGroupItem, Card } from 'reactstrap';

import axios from 'src/utils/axios';
import { AskText, AskFile, AskSelect, AskConfirm } from 'src/components/questions';
import { Link } from 'react-router-dom';
import Spinner from 'src/components/spinner';

export default function Room() {
	const history = useHistory();
	const { id } = useParams();

	const [room, setRoom] = useState(null);

	const loadRoom = useCallback(async () => {
		try {
			var res = await axios.get(`/room/${id}`);
			setRoom(res.data);
		} catch (error) {
			alert('Failed to get room' + error.message);
		}
	}, [id]);

	useEffect(() => {
		loadRoom();
	}, [loadRoom]);

	const updateState = async (key, value) => {
		try {
			var _room = { ...room };
			_room[key] = value;
			await axios.post(`/room/${id}/update`, _room);
			await loadRoom();
		} catch (error) {
			return 'There was a server issue.';
		}
	};

	const createTask = async (type) => {
		try {
			var res = await axios.post('/task/create', { type, room_id: id });
			history.push(`/task/${res.data.insertId}`);
		} catch (error) {
			return 'There was a server issue.';
		}
	};

	const deleteTask = async (id) => {
		try {
			await axios.post('/task/delete', { task_id: id });
			await loadRoom();
		} catch (error) {
			return 'There was a server issue.';
		}
	};

	const createClue = async (value) => {
		try {
			await axios.post(`/room/${id}/create-clue`, { value });
			await loadRoom();
		} catch (error) {
			return 'There was a server issue.';
		}
	};

	const editClue = async (index, v, delta) => {
		try {
			if (delta > 0) room.clues[index].list_order += 15;
			if (delta < 1) room.clues[index].list_order -= 15;
			room.clues[index].value = v;
			console.log(room.clues);
			await axios.post(`/room/${id}/update-clues`, { clues: room.clues });
			await loadRoom();
		} catch (error) {
			return 'There was a server issue.';
		}
	};

	if (!room) return <Spinner />;
	return (
		<Container>
			<h1>
				Room - {room.room_name}
				<Link to={`/activity/${room.activity_id}`}>
					<Button color="secondary" outline className="float-right mt-2">
						<i className="fa fa-arrow-left" aria-hidden="true"></i> Return
					</Button>
				</Link>
			</h1>
			<hr />
			<Row>
				<Col md={6}>
					<Card style={{ padding: '20px' }}>
						Room Name
						<h5>
							{room.room_name} &nbsp;
							<AskText value={room.room_name} onSubmit={async (v) => await updateState('room_name', v)} />
						</h5>
						<br />
						Room Welcome Message
						<h5>
							{room.welcome} &nbsp;
							<AskText value={room.welcome} onSubmit={async (v) => await updateState('welcome', v)} />
						</h5>
						<br />
						Room Background
						<h5>
							<AskFile
								onSubmit={async ({ url, file_id }) => {
									await updateState('background_image', file_id);
								}}
							/>
						</h5>
						<img style={{ maxWidth: '300px', maxHeight: '600px' }} src={room.background_image_url} alt="background"></img>
					</Card>
				</Col>
				<Col md={6}>
					<h3>
						Tasks &nbsp;
						<AskSelect
							value=""
							values={room.task_types.map((t) => {
								return { value: t.type_id, text: t.type_name };
							})}
							onSubmit={async (v) => await createTask(v)}
							title="Create Task"
							label="Task Type"
						>
							<Button color="primary" outline className="ml-auto">
								<i className="fa fa-plus" aria-hidden="true"></i> Create Task
							</Button>
						</AskSelect>
						<Link to={`/room/${id}/configure`}>
							<Button color="primary" outline className="ml-auto">
								<i className="fa fa-hammer" aria-hidden="true"></i> Configure
							</Button>
						</Link>
					</h3>
					<ListGroup className="mt-2">
						{room.tasks.map((t) => {
							if (t?.task_default)
								return null

							return (
								<ListGroupItem key={t.task_id}>
									<Link to={`/task/${t.task_id}`}>
										{t.task_title} - ({t.type_name})
									</Link>

									<div className="float-right">
										<AskConfirm description="Delete this task?" onSubmit={() => deleteTask(t.task_id)}>
											<i style={{ color: 'red', cursor: 'pointer' }} className="fa fa-times fa-md" />
										</AskConfirm>
									</div>
								</ListGroupItem>
							);
						})}
					</ListGroup>

					<h3 className="mt-4">
						Clues &nbsp;
						<AskText onSubmit={async (v) => await createClue(v)}>
							<Button color="primary" outline className="ml-auto">
								<i className="fa fa-plus" aria-hidden="true"></i> Add Clue
							</Button>
						</AskText>
					</h3>
					<ListGroup className="mt-2">
						{room.clues.map((c, index) => {
							return (
								<ListGroupItem key={c.clue_id}>
									{c.value} &nbsp;
									<AskText value={c.value} onSubmit={async (v) => await editClue(index, v)} />
									<i className="fa fa-arrow-up ml-2" onClick={() => editClue(index, c.value, -1)} />
									<i className="fa fa-arrow-down ml-2" onClick={() => editClue(index, c.value, 1)} />
								</ListGroupItem>
							);
						})}
					</ListGroup>
				</Col>
			</Row>
		</Container>
	);
}
