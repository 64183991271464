import { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button, Card, Table } from 'reactstrap';
import axios from 'src/utils/axios';
import Spinner from 'src/components/spinner';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AskText, AskFile, AskConfirm, AskSelect } from 'src/components/questions';

const type = { photo: 1, question: 2, select: 3 };
export default function Task() {
	const { id } = useParams();
	const [task, setTask] = useState(null);

	const loadTask = useCallback(async () => {
		try {
			var res = await axios.get(`/task/${id}`);
			setTask(res.data);
		} catch (error) {
			return error;
		}
	}, [id]);

	const updateState = async (key, value) => {
		var _task = { ...task };
		_task[key] = value;
		await axios.post(`/task/${id}/update`, _task);
		await loadTask();
	};

	const createAnswer = async (answer_value) => {
		try {
			await axios.post(`/answer/create`, { answer_value, task_id: id });
			await loadTask();
		} catch (error) {
			return error;
		}
	};

	const updateAnswer = async (answer_id, key, value) => {
		try {
			var _answer = task.answers.find((a) => a.answer_id === answer_id);
			_answer[key] = value;
			await axios.post(`answer/${answer_id}/update`, _answer);
			await loadTask();
		} catch (error) {
			return error;
		}
	};

	const deleteAnswer = async (answer_id) => {
		try {
			await axios.get(`answer/${answer_id}/delete`);
			await loadTask();
		} catch (error) {
			return error;
		}
	};

	useEffect(() => {
		loadTask();
	}, [loadTask]);

	if (!task) return <Spinner />;
	return (
		<Container>
			<h1>
				Task - {task.task_title}
				<Link to={`/room/${task.room_id}`}>
					<Button color="secondary" outline className="float-right mt-2">
						<i className="fa fa-arrow-left" aria-hidden="true"></i> Return
					</Button>
				</Link>
			</h1>
			<hr />
			<Row>
				<Col md={6}>
					<Card style={{ padding: '20px' }}>
						Task Title
						<h5>
							{task.task_title}
							<AskText value={task.task_title} onSubmit={async (v) => await updateState('task_title', v)} />
						</h5>
						<br />
						Task Description
						<h5>
							{task.task_description}
							<AskText value={task.task_description} onSubmit={async (v) => await updateState('task_description', v)} />
						</h5>
						<br />
						Photo Based Task <small>Show image bigger on presenter </small>
						<h5>
							{task.photo_based ? 'Yes' : 'No'}
							<AskSelect
								value={task.photo_based}
								values={[
									{ value: 0, text: 'No' },
									{ value: 1, text: 'Yes' }
								]}
								onSubmit={async (v) => await updateState('photo_based', v)}
								title="Choose a option"
								label="Photo Based"
							></AskSelect>
						</h5>
						<br />
						Task Image
						<h5>
							<AskFile onSubmit={async ({ file_id }) => await updateState('task_image', file_id)} />
						</h5>
						<img style={{ maxWidth: '300px', maxHeight: '600px' }} src={task.task_image_url} alt="This task" />
						<br />
						Able to retry task?
						<h5>
							{task.retry ? 'Yes' : 'No'}
							<AskSelect
								value={task.reward_room}
								values={[
									{ value: 0, text: 'No' },
									{ value: 1, text: 'Yes' }
								]}
								onSubmit={async (v) => await updateState('retry', v)}
								title="Choose a option"
								label="Retry task"
							></AskSelect>
						</h5>
						<br />
						Save answer? (Wont score and leave icon)
						<h5>
							{task.save_answer ? 'Yes' : 'No'}
							<AskSelect
								value={task.save_answer}
								values={[
									{ value: 0, text: 'No' },
									{ value: 1, text: 'Yes' }
								]}
								onSubmit={async (v) => await updateState('save_answer', v)}
								title="Choose a option"
								label="Save answer?"
							></AskSelect>
						</h5>
						<br />
						Reward (Maximum Points)
						<h5>
							{task.reward}
							<AskText value={task.reward} onSubmit={async (v) => await updateState('reward', v)} />
						</h5>
						<br />
						Required Photo Tasks (In room)
						<h5>
							{task.required_room_photos}
							<AskText value={task.required_room_photos} onSubmit={async (v) => await updateState('required_room_photos', v)} />
						</h5>
						<br />
						Required Question Tasks (In room)
						<h5>
							{task.required_room_questions}
							<AskText value={task.required_room_questions} onSubmit={async (v) => await updateState('required_room_questions', v)} />
						</h5>
						<br />
						Reward (Travel to room)
						<h5>
							{task.otherRooms.find((r) => task.reward_room === r.room_id)?.room_name}
							<AskSelect
								value={task.reward_room}
								values={task.otherRooms.map((r) => {
									return { value: r.room_id, text: r.room_name };
								})}
								onSubmit={async (v) => await updateState('reward_room', v)}
								title="Choose a room (optional)"
								label="Rooms"
							></AskSelect>
						</h5>
						<br />
						Reward Clue
						<h5>
							{task.reward_clue}
							<AskSelect
								value={task.reward_clue}
								values={[
									{ value: 'ROOM', text: 'ROOM' },
									{ value: 'ACTIVITY', text: 'ACTIVITY' },
									{ value: 'MAP', text: 'MAP' }
								]}
								onSubmit={async (v) => await updateState('reward_clue', v)}
								title="What type of clue to give?"
								label="Clue types"
							></AskSelect>
						</h5>
						<br />
						Correct answer message
						<h5>
							{task.correct_message}
							<AskText value={task.correct_message} onSubmit={async (v) => await updateState('correct_message', v)} />
						</h5>
						<br />
						{task.task_type_id !== type.photo && (
							<>
								Incorrect answer message
								<h5>
									{task.incorrect_message}
									<AskText value={task.incorrect_message} onSubmit={async (v) => await updateState('incorrect_message', v)} />
								</h5>
								<br />
							</>
						)}
					</Card>
				</Col>
				{task.task_type_id !== type.photo && (
					<Col md={6}>
						<Card style={{ padding: '20px' }}>
							<h5>
								Expected Answers
								<AskText onSubmit={createAnswer}>
									<Button outline className="float-right">
										<i className="fa fa-plus" /> Create Answer
									</Button>
								</AskText>
							</h5>
							<Table>
								<thead>
									<tr>
										<th>Answer</th>
										{task.task_type_id === type.select && <th>Correct</th>}
										<th></th>
									</tr>
								</thead>
								<tbody>
									{task.answers.map((a) => {
										return (
											<tr>
												<td>
													{a.answer_value} &nbsp;
													<AskText
														value={a.answer_value}
														onSubmit={async (v) => {
															await updateAnswer(a.answer_id, 'answer_value', v);
														}}
													/>
												</td>
												{task.task_type_id === type.select && (
													<td
														onClick={async (v) => {
															setTask(null);
															return await updateState('multiple_choice_answer', a.answer_id);
														}}
														style={{ cursor: 'pointer' }}
													>
														<span title="Is this multiple choice?">
															{task.multiple_choice_answer === a.answer_id ? <i className="far fa-check-square" /> : <i className="far fa-square" />}
														</span>
													</td>
												)}
												<td>
													<AskConfirm
														description="Delete this question?"
														onSubmit={async (res) => {
															await deleteAnswer(a.answer_id);
														}}
													>
														<i style={{ color: 'red', cursor: 'pointer' }} className="fa fa-times fa-md" />
													</AskConfirm>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</Card>
					</Col>
				)}
			</Row>
		</Container>
	);
}
