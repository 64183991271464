import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Spinner from 'src/components/spinner';
import axios from 'src/utils/axios';
import { Container, Row, Card, CardImg, CardBody, Col, CardText } from 'reactstrap';

import './style.css';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

export default function Slideshow() {
	const [data, setData] = useState();
	const { id } = useParams();

	const [selectedSlide, setSelectedSlide] = useState(0);

	const loadActivity = useCallback(async () => {
		try {
			const data = (await axios.get(`/slideshow/${id}`)).data;
			console.log(data);
			setData(data);
		} catch (error) {
			console.error(error);
		}
	}, [id]);

	const keyPressed = useCallback(
		(key) => {
			if (!data) return;
			let newIndex = selectedSlide;
			if (key.key === 'ArrowLeft') {
				newIndex--;
			}
			if (key.key === 'ArrowRight') {
				newIndex++;
			}
			console.log(data.slides.length);
			newIndex = Math.min(data.slides.length - 1, Math.max(0, newIndex));
			console.log(newIndex);
			setSelectedSlide(newIndex);
		},
		[data, selectedSlide]
	);
	useEffect(loadActivity, [loadActivity]);
	useEffect(() => {
		console.log(window);
		// document.documentElement.requestFullscreen();
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', keyPressed);
		return () => document.removeEventListener('keydown', keyPressed);
	}, [keyPressed]);
	if (!data) return <Spinner />;

	const { title, images } = data.slides[selectedSlide];
	if (data.slides.length < 2) return <h1>No slides yet...</h1>;
	return (
		<Container
			fluid
			style={{ height: '100vh', width: '100%', textAlign: 'center', backgroundImage: `url(${data.background}`, backgroundSize: 'cover' }}
		>
			<div
				style={{
					position: 'absolute',
					zIndex: 1000,
					right: '2vw',
					bottom: '2vw',
					background: 'rgba(255,255,255,0.4)',
					padding: '0.5vw',
					borderRadius: '0.5vw'
				}}
			>
				<img src={data.brand_small} style={{ maxWidth: '13vw', maxHeight: '13vh' }} alt=""></img>
			</div>
			<SwitchTransition mode={'out-in'}>
				<CSSTransition
					key={selectedSlide}
					addEndListener={(node, done) => {
						node.addEventListener('transitionend', done, false);
					}}
					classNames="fade"
				>
					<Slide title={title} images={images} data={data} />
				</CSSTransition>
			</SwitchTransition>
		</Container>
	);
}

const Slide = ({ style, title, images, data }) => {
	return (
		<div style={{ ...style, textAlign: 'center', overflow: 'hidden' }}>
			<h1 style={{ marginTop: '50px', fontSize: '4vw' }}>
				<span style={{ background: 'rgba(255,255,255,0.7)', borderRadius: '32px', padding: '4px 24px' }}>{title}</span>
			</h1>
			<Row style={{ marginTop: '4rem', justifyContent: 'center', alignItems: 'center' }}>
				{images.map((image, index) => {
					return (
						<Col lg={Math.min(5, Math.floor(12 / images.length))}>
							<Card style={{ margin: '1rem', padding: 4, background: 'rgba(255,255,255,0.7)' }}>
								<CardImg key={index} alt="" style={{ maxWidth: `50vw`, maxHeight: `50vh` }} src={image.url} />
								<CardBody>
									<CardText style={{ display: 'block', fontSize: '2vw' }}>{image.description}</CardText>
								</CardBody>
							</Card>
						</Col>
					);
				})}
				{images.length < 1 && data.brand_large && (
					<Col lg={Math.min(5, Math.floor(12 / images.length))}>
						<Card style={{ margin: '1rem', padding: 4, background: 'rgba(255,255,255,0.7)' }}>
							<CardImg alt="" style={{ maxWidth: `50vw`, maxHeight: `50vh` }} src={data.brand_large} />
							{/* <CardBody>
								<CardText style={{ display: 'block', fontSize: '2vw' }}></CardText>
							</CardBody> */}
						</Card>
					</Col>
				)}
			</Row>
		</div>
	);
};
