import React from 'react';

const NoEditor = (props) => {
	return (
		<>
			<h3>There is no editor availabe for this File type</h3>
			<span>Contact support to add a feature for this</span>
		</>
	);
};

export default NoEditor;
