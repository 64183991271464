import React from 'react';

import NoEditor from './NoEditor';

let editors = require('./index');

const Components = { ...editors };

const EditorSwitch = (props) => {
	// component does exist
	if (typeof Components[props?.type] !== 'undefined') {
		return React.createElement(Components[props.type], {
			...props
		});
	}
	// component doesn't exist yet

	return React.createElement(NoEditor, {
		...props
	});
};

export default EditorSwitch;
