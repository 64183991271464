import { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';

export default function AskConfirm(props) {
	const { title = 'Are you sure?', description = '', submitLabel = 'Yes', onSubmit = () => {} } = props;
	const [open, setOpen] = useState(false);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState(false);

	const openDialog = () => {
		setError(false);
		setSaving(false);
		setOpen(true);
	};

	const handleSubmit = async (response) => {
		if (saving) return;
		setSaving(true);
		setError(false);
		let res = null;
		res = await onSubmit(response);
		setSaving(false);
		if (!res) return setOpen(false);
		setError(res);
	};

	const openButton = props.children ? (
		<span onClick={openDialog}>{props.children}</span>
	) : (
		<i id="askConfirmTooltip" onClick={openDialog} className="fa fa-pencil-alt" />
	);

	return (
		<>
			{openButton}
			{!props.children && (
				<>
					<UncontrolledTooltip placement="right" target="askConfirmTooltip" trigger="hover">
						Click to edit
					</UncontrolledTooltip>
				</>
			)}
			<Modal fullWidth isOpen={open} toggle={() => setOpen(!open)} aria-labelledby="form-dialog-title">
				<ModalHeader id="form-dialog-title">{title}</ModalHeader>
				<ModalBody>
					{description && description}
					<p className="text-danger font-weight-bold">{error}</p>
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => setOpen(false)} color="secondary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="primary">
						{saving ? <i className="mx-auto fa fa-cog fa-spin ml-2" /> : submitLabel}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
