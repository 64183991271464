import React, { useState, useRef, useEffect } from 'react';
import PhoneView from './PhoneView';
import { Badge } from 'reactstrap';

export default function MainScreen({ state }) {
	const calcLocation = (x, y) => {
		return [x * (size?.w ?? 0.5), y * (size?.h ?? 0.5)];
	};
	const [size, setSize] = useState(null);

	const imageRef = useRef();

	function handleResize() {
		if (imageRef.current) setSize({ w: imageRef.current.clientWidth, h: imageRef.current.clientHeight });
	}

	useEffect(() => {
		handleResize();
	}, [imageRef]);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return (_) => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<PhoneView noPadding>
			<div
				ref={imageRef}
				style={{
					height: '100%',
					width: '100%',
					backgroundImage: `url("${state.roomBackground ?? ''}")`,
					backgroundSize: 'cover',
					backgroundPosition: 'center'
				}}
			>
				<div
					style={{
						position: 'absolute',
						left: 0,
						right: 0,
						top: 0,
						bottom: 0
					}}
				>
					<span
						style={{
							position: 'absolute',
							backgroundColor: '#111',
							borderRadius: '10px',
							padding: '3px 15px',
							fontWeight: 'bold',
							fontSize: '1.2em',
							whiteSpace: 'nowrap',
							top: '5px',
							left: '7px',
							color: '#eee',
							display: 'none'
						}}
					>
						{state.roomTitle}
					</span>
					{state.tasks.map((task, index) => {
						if (!task.active) return false;
						const [x, y] = calcLocation(task.x, task.y);
						const [w, h] = calcLocation(task.width, task.height);
						var icon;
						switch (task.taskType) {
							case 'photo':
								icon = 'fa fa-camera';
								break;
							case 'question':
								icon = 'fa fa-question-circle';
								break;
							case 'select':
								icon = 'fa fa-list';
								break;

							default:
								break;
						}
						return (
							<div
								key={index}
								style={{
									position: 'absolute',
									zIndex: 5,
									top: y,
									left: x
								}}
							>
								{task.task_icon ? (
									<img
										style={{
											width: w,
											height: h
										}}
										src={task.task_icon}
										alt="Task icon"
									/>
								) : (
									<div
										style={{
											backgroundColor: task.active ? 'yellow' : 'grey',
											border: '1px solid black',
											borderRadius: 100,
											padding: '5px',
											width: 'auto',
											height: 'auto',
											alignItems: 'center',
											justifyContent: 'center',
											whiteSpace: 'nowrap'
										}}
									>
										<i className={icon} />
										&nbsp;
										<span
											style={{
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis'
											}}
										>
											{task.taskTitle}
										</span>
										&nbsp;
									</div>
								)}
							</div>
						);
					})}
					<CustomIcon
						badge={state.unread_clue}
						calcLocation={calcLocation}
						icon={state.clue_icon_url}
						x={state.clue_icon_x}
						y={state.clue_icon_y}
						w={45}
						h={45}
						label="Clues"
					/>
					<CustomIcon calcLocation={calcLocation} icon={state.help_icon_url} x={state.help_icon_x} y={state.help_icon_y} w={45} h={45} label="Help" />
					{
						state.show_map_icon &&
						<CustomIcon
							calcLocation={calcLocation}
							icon={state.help_icon_url}
							x={state.map_icon_x}
							y={state.map_icon_y}
							w={45}
							h={45}
							label={state.map_label}
						/>
					}
				</div>
			</div>
		</PhoneView>
	);
}

function CustomIcon(props) {
	var { calcLocation, icon, x, y, w, h, badge, label } = props;

	[x, y] = calcLocation(x, y);

	return (
		<div
			style={{
				position: 'absolute',
				zIndex: 5,
				top: y,
				left: x
			}}
		>
			{badge && (
				<Badge color="danger" style={{ position: 'absolute', fontSize: '0.9em', top: 45 }}>
					NEW
				</Badge>
			)}
			{icon ? (
				<img
					style={{
						width: w,
						height: h
					}}
					alt=""
					src={icon}
				/>
			) : (
				<b style={{ background: 'yellow', padding: '2px 10px', borderRadius: '12px', border: '1px black' }}>{label}</b>
			)}
		</div>
	);
}
