import * as React from 'react';
import { Auth, Hub } from 'aws-amplify'

const AppContext = React.createContext();

function AppProvider({ children }) {
	const [settings, setSettingsValues] = React.useState(JSON.parse(localStorage.getItem('settings')));
	const [token, setTokenValues] = React.useState(localStorage.getItem('ah_token') || null);

	const [user, setUserValues] = React.useState({})
	const [isLoaded, setIsLoaded] = React.useState(false)



	const setSettings = (val) => {
		localStorage.setItem('settings', JSON.stringify(val));
		setSettingsValues(val);
	};

	React.useEffect(() => {

		updateCurrentUser()
		Hub.listen('auth', onHubCapsule);
	}, [])

	const onHubCapsule = (capsule) => {
		console.log(capsule)
		const { channel, payload } = capsule;
		if (channel === 'auth' && payload.event !== 'signIn') {
			updateCurrentUser()
		}
	}
	const updateCurrentUser = async (user) => {
		if (user) {
			setSettingsValues(user)
			return
		}
		try {
			const user = await Auth.currentAuthenticatedUser()
			localStorage.setItem('access_token', user.signInUserSession.accessToken.jwtToken);
			setUserValues(user)
			setIsLoaded(true)
		} catch (err) {
			setUserValues(null)
			setIsLoaded(true)
		}
	}


	const isAuthenticated = user && user.username ? true : false
	const value = {
		isAuthenticated,
		settings,
		setSettings: setSettings,
		isLoaded,
		user,
		logout: ()=>Auth.signOut(),
		updateCurrentUser
	};
	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
function useApp() {
	const context = React.useContext(AppContext);
	if (context === undefined) {
		throw new Error('useApp must be used within a AppProvider');
	}
	return context;
}
export { AppProvider, useApp };
