import React, { useState, useContext } from 'react';

import OutsideClickHandler from 'react-outside-click-handler';
import File from './File';

// const Filter = (filter, file) => {
//     return true;
// }
import FileContext from './FileContext';
// import { MenuItem } from 'react-contextmenu';

import styled from 'styled-components';

// const ContextItem = styled(MenuItem)`
// 	padding-left: 1rem;
// 	padding-right: 1rem;

// 	&:hover {
// 		background-color: grey;
// 	}
// `;
// const ContextDivider = styled.hr`
// 	margin: 0;
// `;

const Folder = (props) => {
	const { children, initialOpen, actions, editable, depth, uuid } = props;
	// const { setSelectedFile } = actions;

	const [title, setTitle] = useState(props.title);
	const [edit, setEdit] = useState(false);

	const [addFolderActive, setAddFolderActive] = useState(false);
	const [addFolderTitle, setAddFolderTitle] = useState('');

	const [open, setOpen] = useState(initialOpen);
	const { selectedFile, setSelectedFile, setContextData } = useContext(FileContext);

	// const withinFilter = !filter && Filter(filter, props)
	// console.log({withinFilter, title})

	let currentDepth = depth ?? 0;
	const thisUuid = currentDepth > 0 ? `${uuid}/${title}` : title;

	const addFolder = (title) => {
		console.log('new folder ' + title);
		setAddFolderActive('');
		setAddFolderActive(false);
		console.log(thisUuid);
	};

	const thisFile = { ...props, uuid: thisUuid };
	if (editable === true || editable === undefined)
		thisFile.rename = () => {
			setEdit(true);
		};

	const setContext = () => {
		setContextData();
		// <>
		// 	{(editable === true || editable === undefined) && (
		// 		<ContextItem
		// 			data={{ foo: 'bar' }}
		// 			onClick={() => {
		// 				setEdit(true);
		// 			}}
		// 		>
		// 			Rename
		// 		</ContextItem>
		// 	)}
		// 	<ContextDivider />
		// 	<ContextItem data={{ foo: 'bar' }} onClick={console.log}>
		// 		Cut
		// 	</ContextItem>
		// 	<ContextItem data={{ foo: 'bar' }} onClick={console.log}>
		// 		Copy
		// 	</ContextItem>
		// 	<ContextItem divider />
		// 	<ContextItem data={{ foo: 'bar' }} onClick={console.log}>
		// 		Paste
		// 	</ContextItem>
		// 	<ContextDivider />
		// 	<ContextItem
		// 		data={{ foo: 'bar' }}
		// 		onClick={() => {
		// 			console.log('folder add');
		// 			setAddFolderActive(true);
		// 		}}
		// 	>
		// 		Add folder
		// 	</ContextItem>
		// 	<ContextItem data={{ foo: 'bar' }} onClick={console.log}>
		// 		Add file
		// 	</ContextItem>
		// </>
	};

	return (
		<div>
			<div
				className={`folder selectable ${thisUuid === selectedFile?.uuid ? 'selected' : ''}`}
				onMouseDown={(e) => {
					if (e.button === 0 && thisUuid === selectedFile?.uuid) setOpen(!open);
					setContext();
				}}
				onMouseUp={(e) => {
					if (e.button === 0 && setSelectedFile) setSelectedFile(thisFile);
				}}
			>
				<div className="selectable" style={{ paddingLeft: currentDepth * 12 + 12 }}>
					<i
						className={`${open ? 'fas fa-caret-down' : 'fas fa-caret-right'} pr-2`}
						style={{ marginRight: open ? 0 : 4 }}
						onMouseUp={(e) => {
							e.preventDefault();
							if (thisUuid !== selectedFile?.uuid) setOpen(!open);
						}}
					/>

					{edit ? (
						<OutsideClickHandler
							display={'contents'}
							onOutsideClick={() => {
								setEdit(false);
							}}
						>
							<input
								value={title}
								onChange={(e) => {
									setTitle(e.target.value);
								}}
								onKeyPress={(e) => {
									if (e.key === 'Enter') setEdit(false);
								}}
							/>
						</OutsideClickHandler>
					) : (
						<small>{title}</small>
					)}
				</div>
			</div>
			<div style={{ display: open ? 'block' : 'none' }}>
				{addFolderActive && (
					<OutsideClickHandler
						display={'contents'}
						onOutsideClick={() => {
							setAddFolderActive(false);
							setAddFolderTitle('');
						}}
					>
						<input
							value={addFolderTitle}
							onChange={(e) => {
								setAddFolderTitle(e.target.value);
							}}
							onKeyPress={(e) => {
								if (e.key === 'Enter') addFolder(addFolderTitle);
							}}
						/>
					</OutsideClickHandler>
				)}
				{children &&
					children.map((data, index) => {
						// console.log(data?.actions)
						if (data.children)
							return (
								<Folder
									key={index}
									{...data}
									actions={{ ...actions, ...data?.actions }}
									selectedFile={selectedFile}
									uuid={thisUuid}
									depth={currentDepth + 1}
								/>
							);
						else
							return (
								<File
									key={index}
									{...data}
									actions={{ ...actions, ...data?.actions }}
									selectedFile={selectedFile}
									uuid={thisUuid}
									depth={currentDepth + 1}
								/>
							);
					})}
			</div>
		</div>
	);
};

// Folder.propTypes = {
//     title: PropTypes.string.isRequired,
//     children: PropTypes.array.isRequired,
// };

export default Folder;
