import React from 'react';

import { Container, Row, Col } from 'reactstrap';

const ImageViewer = (props) => {
	const { file } = props;

	const { title, type, description, width, height, data } = file;
	console.log(file);

	return (
		<Container className="noselect" fluid style={{ height: '100%', display: 'flex', flexFlow: 'column' }}>
			<Row style={{ flexGrow: 1 }}>
				<Col md={10} className="pr-0 pl-0">
					<div style={{ width: '100%' }}>
						<img src={file?.data?.url} alt="" width={300} />
					</div>
				</Col>
				<Col md={2}>
					<Row style={{ border: '1px solid grey' }}>
						<Col>
							Properties
							<hr />
							<table>
								<tr>
									<td>Title</td>
									<td>{title}</td>
								</tr>
								<tr>
									<td>type</td>
									<td>{type}</td>
								</tr>
								<tr>
									<td>description</td>
									<td>{description}</td>
								</tr>
								<tr>
									<td>Url</td>
									<td>{data?.url}</td>
								</tr>
								<tr>
									<td>Size</td>
									<td>{`${width}/${height}`}</td>
								</tr>
							</table>
							{/* <span>{` ${title}`}</span>
                            <span>{`type ${type}`}</span>
                            <span>{`Description ${description}`}</span>
                            <span>{` ${data?.url}`}</span> */}
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default ImageViewer;
